import {
    EncompassConstants,
    Messages,
    coBorrowerFieldIds,
    requiredFieldIds
} from "constants/strings";

// import { parseContactPath } from "./saveOrderupdates";

export function isLoanPurposeValid (loanPurpose: string): boolean {
    const validLoanPurposes: Record<string, boolean> = {
        [EncompassConstants.CashOutRefinance]: true,
        [EncompassConstants.NoCashOutRefinance]: true,
        [EncompassConstants.Purchase]: true,
        [EncompassConstants.Construction]: true,
        [EncompassConstants.ConstructionToPermanent]: true
    };

    return validLoanPurposes[loanPurpose] || false;
}


function validateLoanPurpose (loanPurposeType: string): string {
    let errorMessage: string = "";

    const isValid: boolean = isLoanPurposeValid(loanPurposeType);

    if (!isValid) {
        errorMessage = Messages.AcceptedLoanPurpose;
    }

    return errorMessage;
}
export function validateSelectedOffice (
    selctedRadio: string,
    SelectedOfficeId: string
): string {
    let errorMessage: string = "";

    const isofficeSelected = selctedRadio?.includes("Centralized") ?
        false :
        isNullOrEmpty(SelectedOfficeId);
    if (isofficeSelected) {
        errorMessage = Messages.OfficeLocationRequired;
    }

    return errorMessage;
}

interface ILoan {
  Fields: {
    getValue(fieldId: string): string;
  };
}

export function isNullOrEmpty (
    value: string | number | null | undefined
): boolean {
    return (
        value === null ||
    value === undefined ||
    (typeof value === "string" && value.trim() === "") ||
    value === "undefined" || value === "null" ||
    (typeof value === "number" && isNaN(value))
    );
}

function validateRequiredFields (loanInfo: ILoan): string {
    const missingFields: string[] = [];

    const getFieldStringValue = (fieldId: string): string => {
        return loanInfo.Fields.getValue(fieldId);
    };

    const checkAndAddMissingField = (fieldId: string, fieldName: string) => {
        if (isNullOrEmpty(getFieldStringValue(fieldId))) {
            missingFields.push(fieldName);
        }
    };

    requiredFieldIds.forEach((fieldId) =>
        checkAndAddMissingField(
            fieldId,
            fieldId.replace(/([a-z])([A-Z])/g, "$1 $2").trim()
        )
    );

    const data = coBorrowerFieldIds.some((fieldId) => {
        return !isNullOrEmpty(getFieldStringValue(fieldId));
    });
    if (
        coBorrowerFieldIds.some((fieldId) => {
            return !isNullOrEmpty(getFieldStringValue(fieldId));
        })
    ) {
        coBorrowerFieldIds.forEach((fieldId) =>
            checkAndAddMissingField(
                fieldId,
                `Co-Borrower's ${fieldId.replace("CoBorrower", "").trim()}`
            )
        );
    }

    return missingFields.length > 0 ?
        `The following field(s) are required to place an order: ${missingFields.join(
            ", "
        )}` :
        "";
}

export function validateOrder (loanInfo: any): string {
    let errorMessage: string = "";
    const { loanPurposeType } = loanInfo;
    const LoanInfo: any = {
        Fields: {
            getValue: (fieldId: string) => {
                return loanInfo[fieldId];
            }
        }
    };

    errorMessage = validateLoanPurpose(loanPurposeType);

    if (!errorMessage) {

        if (!errorMessage) {
            errorMessage = validateRequiredFields(LoanInfo);

            if (errorMessage) {
                errorMessage += "\n\n";
            }
        }
    }

    return errorMessage;
}
