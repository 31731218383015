import React from "react";
import { List, ListItem, ListItemText, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "interfaces/rootState";
import host from "@elliemae/em-ssf-guest";
import { getRateCalculatorURL } from "utils/helpers";
import { logException } from "../Logger";

interface Props {}

const listItemStyles = {
  listItem: {
    p: "2px 4px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },
};
const aStyles = {
  textDecoration: "none",
  color: "#000",
  fontSize: 15,
  padding: "4px",
};

const HelpMenu: React.FC<Props> = () => {
  const {
    data: {
      loanNumber = "",
      lenderClientName = "",
      lenderEmailAddress = "",
      lenderPhoneNumber = "",
    },
  } = useSelector((st: RootState) => st.loanParams) || {};

  const { StewartContextID = "Not Available" } = useSelector(
    (st: RootState) => st.loan.options
  );

  const loan = useSelector((state: RootState) => state.loan.data.loan) ?? {};
  const Loan = useSelector((state: RootState) => state.loan);
  const { PricingGuid } = useSelector(
    (st: RootState) => st.officeData?.officeData?.Stewart?.ContractInfo || {}
  );

  const { property } = loan;
  const { city, state, county, loanPurposeType } = property ?? {};
  const phoneNum = lenderPhoneNumber?.includes("null") ? "" : lenderPhoneNumber;

  // const handleItemClick = (itemName: string) => {
  //   console.log("Clicked on:", itemName);
  // };

  return (
    <List
      sx={{
        p: 0,
        width: "100%",
        maxWidth: 360,
        borderRadius: 2,
        backgroundColor: "background.paper",
        // border: "1px solid lightgray",
      }}
      aria-label="help section"
    >
      <ListItem
        sx={listItemStyles.listItem}
        // onClick={() => handleItemClick("Request Technical Support")}
      >
        {/* <ListItemText primary="Request Technical Support" /> */}
        <a
          style={aStyles}
          href={`mailto:customercare@stewart.com?subject=Encompass%20Lender%20Requesting%20Technical%20Support&body=I%20am%20having%20difficulty%20with%20the%20Encompass%20Plugin%20for%20Stewart%20Title%20Company.%0A%0ALoan%20Number:%20${loanNumber}%0AContextID:%20${StewartContextID}%0A%0AYour%20Contact%20Information%0AName:%20${lenderClientName}%0ACompany%20Name:%20Demo%20Account%20-%20CSO%235%0AEmail%20Address:%20${lenderEmailAddress}%0APhone:%20${phoneNum}%0A%0ADescribe%20your%20issue:%20`}
          // href={`mailto:customercare@stewart.com?subject=Encompass%20Lender%20Requesting%20Technical%20Support&body=Dear%20Stewart%20Title%20Company%20Customer%20Care%20Team,%0A%0AI%20hope%20this%20email%20finds%20you%20well.%0A%0AI%20am%20writing%20to%20seek%20assistance%20with%20the%20Encompass%20Plugin%20for%20Stewart%20Title%20Company.%20I%20am%20encountering%20difficulties%20and%20would%20greatly%20appreciate%20your%20technical%20support%20to%20resolve%20the%20issue%20promptly.%0A%0ABelow%20are%20the%20details%20related%20to%20my%20request:%0A%0A-%20Loan%20Number:%20${loanNumber}%0A-%20ContextID:%20${StewartContextID}%0A-%20Contact%20Information:%0A%20%20%20%20-%20Name:%20${lenderClientName}%0A%20%20%20%20-%20Company%20Name:%20Demo%20Account%20-%20CSO%235%0A%20%20%20%20-%20Email%20Address:%20${lenderEmailAddress}%0A%20%20%20%20-%20Phone:%20${phoneNum}%0A%0ACould%20you%20kindly%20investigate%20this%20matter%20at%20your%20earliest%20convenience?%20Your%20prompt%20attention%20to%20this%20request%20would%20be%20highly%20valued.%0A%0AThank%20you%20for%20your%20assistance.%0A%0AWarm%20regards,%0A[Your%20Name]`}
        >
          Request Technical Support
        </a>
      </ListItem>
      <Divider component="li" sx={{ m: 0, borderColor: "lightgray" }} />
      <ListItem
        sx={listItemStyles.listItem}
        // onClick={() => handleItemClick("Stewart EPC User Guide")}
      >
        {/* <ListItemText primary="Stewart EPC User Guide" /> */}
        <a
          href="#"
          style={aStyles}
          // href="https://www.stewart.com/content/dam/PI/Products/Encompass/StewartTitleCompanyPlugin_UserGuide.pdf"
          onClick={async () => {
            try {
              const applicationObject = await host.getObject("application");
              const success = await applicationObject.open({
                target:
                  "https://www.stewart.com/content/dam/PI/Products/Encompass/StewartTitleCompanyPlugin_UserGuide.pdf",
              });

              console.log(success);
            } catch (error) {
              console.log({ error });
              logException("Error opening user guide pdf from help screen", {
                error: error || {},
                message: "Error opening user guide pdf from help screen",
              });
            }
          }}
        >
          Stewart EPC User Guide
        </a>
      </ListItem>
      <Divider component="li" sx={{ m: 0, borderColor: "lightgray" }} />
      <ListItem
        sx={listItemStyles.listItem}
        // onClick={() => handleItemClick("Stewart Rate Calculator User Guide")}
      >
        <a
          style={aStyles}
          href={getRateCalculatorURL(
            loanPurposeType,
            state,
            county,
            city,
            Loan?.data?.product?.partnerId,
            loanNumber,
            Loan.options.IsCTS,
            PricingGuid,
            loan.baseLoanAmount,
            loan.purchasePriceAmount
          )}
          target="_blank"
        >
          Stewart Rate Calculator User Guide
        </a>
        {/* <ListItemText primary="Stewart Rate Calculator User Guide" /> */}
      </ListItem>
      <Divider component="li" sx={{ m: 0, borderColor: "lightgray" }} />
      <ListItem
        sx={listItemStyles.listItem}
        // onClick={() => handleItemClick("Request Transaction Support")}
      >
        <a
          style={aStyles}
          href={`mailto:ctsgpostclosing@stewart.com?subject=Encompass%20Lender%20Requesting%20Transaction%20Support&body=I%20am%20having%20difficulty%20with%20the%20Encompass%20Plugin%20for%20Stewart%20Title%20Company.%0A%0ALoan%20Number:%20${loanNumber}%0AContextID:%20${StewartContextID}%0A%0AYour%20Contact%20Information%0AName:%20${lenderClientName}%0ACompany%20Name:%20Demo%20Account%20-%20CSO%235%0AEmail%20Address:%20${lenderEmailAddress}%0APhone:%20${phoneNum}%0A%0ADescribe%20your%20issue:%20`}
        >
          Request Transaction Support
        </a>

        {/* <ListItemText primary="Request Transaction Support" /> */}
      </ListItem>
    </List>
  );
};

export default HelpMenu;
