import { ApiPathConsts, stringsConstants } from "constants/strings";

import { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Popover, Tooltip } from "@mui/material";
import type React from "react";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"; // Add caret icon

import { getFullUrl } from "utils/UrlMap";
import axiosEcpInstance from "lib/axiosEcp";
import { processEncompassData } from "utils/placeOrder";
import { setOptions } from "redux/features/loanSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "interfaces/rootState";
// import { createLoanUpdates } from "utils/saveOrderupdates";
import {
  createUpdateMessage,
  updateNotesparams,
  updateDocuments,
} from "componnets/organisms/actions/actionsHelpers";
import { getErrorMessage, handleCloseTransaction } from "utils/helpers";
import { isNullOrEmpty } from "utils/ordervalidation";
import EPCModal from "componnets/atoms/EpcModal";
import MultiLineTypography from "componnets/atoms/multiLineText";
import HelpMenu from "componnets/atoms/help";
import { logException } from "componnets/atoms/Logger";
import ExceptionBox from "componnets/atoms/ExceptionBox";

// Define the props for the headerButtonList component
interface headerButtonListProps {}

// headerButtonList component as a Functional Component
const headerButtonList: React.FC<headerButtonListProps> = () => {
  const dispatch = useDispatch();
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("No Updates");
  const [anchorEl, setAnchorEl] = useState(null);

  const Options = useSelector((state: RootState) => state.loan.options);
  const Contacts = useSelector(
    (state: RootState) => state.loan.data.loan?.contacts
  );

  const loan = useSelector((state: RootState) => state.loan.data) ?? {};
  const ctsOffice =
    useSelector(
      (state: RootState) => state.officeData.officeData.Stewart?.OfficeInfo
    ) ?? {};
  const transactionId = useSelector(
    (state: RootState) => state.loan.transactionObject.transactionId
  );
  const {
    isOrderPlaced,
    StewartContextID,
    IsCTS,
    localOfficeAddress,
    clientFileNumber,
    IsCancelled,
  } = Options;
  const [isUpdating, setIsupdating] = useState<boolean>(!isOrderPlaced);
  const Address = IsCTS ? ctsOffice : localOfficeAddress;

  useEffect(() => {
    const isUpdaing = !(isOrderPlaced || IsCancelled);
    setIsupdating(isUpdaing);
  }, [Options]);

  const handleOrdreUpdate = async () => {
    setIsupdating(true);

    try {
      const Path = getFullUrl(
        { queryParams: { contextId: StewartContextID } },
        ApiPathConsts.orderStatus
      );

      const response = await axiosEcpInstance.get(Path);
      const error = getErrorMessage(response.data);
      const HasError = Boolean(error?.length);
      if (HasError) {
        setSnackbarMessage(error);
        setSnackbarOpen(true);
        setIsupdating(false);

        const updatedNotes = updateNotesparams(
          Options.Notes,
          clientFileNumber,
          isOrderPlaced,
          Address?.Name
        );
        dispatch(
          setOptions({ ...Options, Notes: updatedNotes, isOrderPlaced: false })
        );
        return;
      }
      const OrderStatus = await processEncompassData(
        response.data,
        Options,
        transactionId
      );
      // const fileNumber = isNullOrEmpty(fncOrderNumb)
      //   ? OrderStatus?.clientFileNumber || clientFileNumber
      //   : fncOrderNumb;

      // const LoanDocs=processOrderStatus(OrderStatus.Searchdata )
      // const {closingDocument:closeDocs,property:propertyDocs}=LoanDocs
      // const Value = createLoanUpdates(
      //   Contacts,
      //   Address,
      //   fileNumber,
      //   "processing",
      //   propertyDocs,
      //   closeDocs
      // );
      // Reflect.deleteProperty(OrderStatus, "Searchdata");


      // if (OrderStatus.rwDocs?.length) {
      //   Value.resources = OrderStatus.rwDocs.map(  // to do on server side 
      //     ({ id, name, mimeType, type }) => ({
      //       id,
      //       name,
      //       mimeType,
      //       type,
      //     })
      //   );
      // }

      setIsupdating(false);
      if (transactionId?.length) {
        // try {
        //   const updatTransactipon = getFullUrl(
        //     { pathParams: { transaction_id: transactionId } },
        //     ApiPathConsts.updateTransaction
        //   );
        //   await axiosEcpInstance.patch(updatTransactipon, Value);
        // } catch (error) {
        //   console.error("Error in transaction processing:", error);
        // }
      }
      const message = createUpdateMessage(OrderStatus);
      setSnackbarMessage(message);
      setSnackbarOpen(true);

      const updatedNotes = updateNotesparams(
        Options.Notes,
        OrderStatus?.clientFileNumber || clientFileNumber,
        isOrderPlaced,
        Address?.Name
      );
      const updatedDocuments = updateDocuments(
        Options.Documents,
        OrderStatus?.clientFileNumber || clientFileNumber,
        isOrderPlaced
      );

      dispatch(
        setOptions({
          ...Options,
          Notes: updatedNotes,
          Documents: updatedDocuments,
          ...OrderStatus,
        })
      );
    } catch (error: unknown) {
      setIsupdating(false);
      logException("Err at handleOrderUpdate [HeaderButtonList]", {
        error: error || {},
        message: "Err at handleOrderUpdate [HeaderButtonList]",
      });
      console.error(error);
    } finally {
      setIsupdating(false);
    }
  };

  const handleSnackbar = (isOpen: boolean) => {
    setSnackbarOpen(isOpen);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box>
      <Tooltip title="Get Order Updates" arrow>
        <span>
          <IconButton onClick={handleOrdreUpdate} disabled={isUpdating}>
            <AutorenewIcon sx={{ fontSize: 24 }} />
          </IconButton>
        </span>
      </Tooltip>

      <Tooltip title={stringsConstants.headerButton2} arrow>
        <IconButton onClick={handleClick}>
          <HelpOutlineIcon />
          {open && <ArrowDropDownIcon />}
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <HelpMenu />
      </Popover>
      <Tooltip title={stringsConstants.headerButton3} arrow>
        <IconButton onClick={handleCloseTransaction}>
          <CloseIcon />
        </IconButton>
      </Tooltip>
      <EPCModal
        open={snackbarOpen}
        onClose={() => handleSnackbar(false)}
        showOkButton
      >
        <MultiLineTypography text={snackbarMessage} />
      </EPCModal>
      <ExceptionBox />
    </Box>
  );
};

export default headerButtonList;
