import React, { ReactNode, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Box } from "@mui/material";
import ButtonLink from "componnets/atoms/hyperLink";
import { useSelector } from "react-redux";
import { State } from "utils/rateCalculator";
import { RootState } from "interfaces/rootState";

interface AccordionProps {
  summary: string;
  summaryColor?: string;
  children: ReactNode;
  reissueText?: string;
  ButtonText?:string;
  onClick?:any
}

const CustomAccordion: React.FC<AccordionProps> = ({
  summary,
  reissueText = "",
  children,
  ButtonText="",
  onClick
}) => {
  const [expanded, setExpanded] = useState(summary.includes("Tran"));
  const isTransac=useSelector((state:RootState)=>state.src.selectedTransObject)??{}

  const toggleAccordion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <Accordion expanded={expanded} onChange={toggleAccordion} disabled={Boolean(!Object.keys(isTransac)?.length)}>
      <AccordionSummary
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: 24, sm: 25, md: 22 },
            color: (theme) => theme.palette.customColors.buttonColor,
          }}
        >
          <b>{summary}</b>
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {expanded ? (
            <ExpandLessIcon
              sx={{
                fontSize: { xs: 24, sm: 24, md: 28 },
                color: (theme) => theme.palette.customColors.buttonColor,
              }}
            />
          ) : (
            <ExpandMoreIcon
              sx={{
                fontSize: { xs: 24, sm: 24, md: 28 },
                color: (theme) => theme.palette.customColors.buttonColor,
              }}
            />
          )}
        </Box>
        {reissueText && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              ml: "auto",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: 14,
                color: (theme) => theme.palette.common.black,
                fontWeight: 700,
              }}
            >
              {reissueText}
            </Typography>
          </Box>
        )}
          {ButtonText && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              ml: "auto",
              alignItems: "center",
            }}
          >
       <ButtonLink label={ButtonText} onClick={onClick&&onClick}/>
          </Box>
        )}
      </AccordionSummary>
      {children}
    </Accordion>
  );
};

export default CustomAccordion;
