import {
  StatusStringsConstantsProps,
  StewartConstantsProps,
  stringsConstantsProps,
} from "interfaces/stringsConstantstype";

export const stringsConstants: stringsConstantsProps = {
  headerButton2: "Help",
  headerButton3: "Close",
  headerTypoLoan: "Loan Number:",
  HederTypoWelcome: "Welcome",
  hideAllCom: "Show last 5 Communications",
  hideAllDocs: "Show last 5 Documents",
  orderTrac: "OrderTrac",
  actions: "Actions",
  obtainFee: "Obtain Fee Quotes",
  placeOrder: "Place Order",
  viewData: "View Data Fields",
  cancelOrder: "Cancel Order",
  payoffsdetails: "Payoff Details",
  importantNotesLabel: "Important Notes",
  //  importantNotes: "Welcome to Stewart Title! Please utilize the Obtain Fee quote or Place order buttons to get started",
  orderDetaillabel: "Order Details",
  orderDate: "Order Date:",
  escrowFileNo: "Escrow File Number:",
  orderDataComparison: "Order Data Comparison:",
  selectOffice: "Select Office",
  centralized: "Centralized",
  localOffice: "Local Office",
  locateOffice: "Locate Office",
  borrowerslabel: "Borrowers",
  propertyInformationLabel: "Property Information",
  communications: "Communications",
  showAllComms: "Show All Communications",
  showAllDocs: "Show All Documents",
  addMessage: "Add Message",
  addDocument: "Add Document",
  loanInformationlabel: "Loan Information",
  documents: "Documents",
  loanType: "Loan Type:",
  loanPurpose: "Loan Purpose:",
  amortization: "Amortization Type:",
  loanAmt: "Loan Amount:",
  purchasedPrice: "Purchase price:",
  estCosting: "Estimated Closing:",
  address: "Address:",
  county: "County:",
  propertyType: "Property Type:",
  borrowerLabel: "Borrower:",
  coBorrowerlabel: "CoBorrower:",
  findOffices: "Find Office(s)",
  cancel: "Cancel",
  distanceMiles: "Distance (miles)",
  locateAnOffice: "Locate an Office",
  browse: "Browse",
};
export const EncompassConstants: Record<string, string> = {
  CompanyName: "Stewart Title Company",
  EFolderDocumentID: "EFolderDocumentID",
  DocumentAttachmentID: "DocumentAttachmentID",
  Purchase: "Purchase",
  NoCashOutRefinance: "NoCash-Out Refinance",
  CashOutRefinance: "Cash-Out Refinance",
  Construction: "ConstructionOnly",
  ConstructionToPermanent: "ConstructionToPermanent",
  PayoffAccountTypeMortgage: "MortgageLoan",
  PayoffAccountTypeHELOC: "HELOC",
};

function dynamicString(key: string, ...args: any[]): string {
  const templates: { [key: string]: (...args: any[]) => string } = {
    OrderConfirmedMessage: (fileNumber: string) =>
      `Thank you for choosing Stewart! We have received your order. Please utilize the Payoff Details button to provide payoff information.Your File number is ${fileNumber}`,
    titleDelivered: (titleVesting: string) =>
      `Title is complete and the curative review is in progress. We will issue curative cleared once all requirements have been satisfied.Title is vested as ${titleVesting}`,
    curativeCleared: (signReq: string) =>
      `File is clear to close the following are required to sign ${signReq}`,
    settlementStatement: (Cash: string) =>
      `The Settlement statement has been delivered. Please send us a message and new Closing Instructions if modifications are needed.Cash due to/from borrower  ${Cash}`,
  };
  return templates[key] ? templates[key](...args) : "Key not found";
}

export const statusStringsConstants: StatusStringsConstantsProps = {
  importantNotes:
    "Welcome to Stewart Title! Please utilize the Obtain Fee quote or Place order buttons to get started",
  "Order Confirmed": (...args: any[]) =>
    dynamicString("OrderConfirmedMessage", ...args),
  "Title Delivered": (...args: any[]) =>
    dynamicString("titleDelivered", ...args),
  "Curative Cleared": (...args: any[]) =>
    dynamicString("curativeCleared", ...args),
  "Settlement Statement Complete": (...args: any[]) =>
    dynamicString("settlementStatement", ...args),
  "Closing Complete": "The signing event has been completed for this order",
  "Loan Funded":
    "This order has funded. All checks and wires have been disbursed. Please review the disbursement summary for additional details",
  "Final Policy": "Final Policy has been issued",
  "Commitment Completed": (...args: any[]) =>
    dynamicString("titleDelivered", ...args),
};

export const Messages: Record<string, string> = {
  AcceptedLoanPurpose:
    "The loan purpose must be a Purchase, Cash-Out Refinance, No Cash-Out Refinance, or Construction.",
  OfficeLocationRequired: "Office Location is required.",
  ValidSelection: "Please select appropriate option to proceed with order.",
  selectvalidOptions: "Please select appropriate option to proceed with order.",
  // OrderConfirmedMessage:
  // "Thank you for your order. Please Click the 'Get Order Updates' button to receive status, document, and message updates.",
  cancelOrderMessage:
    "Your cancellation request has been submitted. Please visit the Status tab for updates.",
};

export const LenderCTSContract: Record<string, number> = {
  NoCTSContract: 0,
  CTSPurchaseOnly: 1,
  CTSRefinanceOnly: 2,
  CTSPurchaseAndRefinance: 3,
} as const;

export const ApiPathConsts: Record<string, string> = {
  orderStatus: "orderStatus",
  placeOrder: "placeOrder",
  getLenderSettings: "getLenderSettings",
  validateAddress: "validateAddress",
  validateZipcode: "validateZipcode",
  sendNotes: "sendNotes",
  updateTransaction: "updateTransaction",
  getStatusList: "getStatusList",
  cancelOrder: "cancelOrder",
  revocation: "revocation",
  getSystemAttributes: "getSystemAttributes",
  getCounty: "getCounty",
  getProviders: "getProviders",
  getPricingPolicyInsuredTypes: "getPolicyInsuredTypes",
  stateSettings: "stateSettings",
} as const;
export const HelocOptions = {
  Off: "0",
  InsuredSelected: "1",
  UninsuredSelected: "2",
} as const;

export const Resonoptions = [
  { code: "Application denied", description: "Application denied" },
  { code: "Apllication Withdrwan", description: "Apllication Withdrwan" },
  {
    code: "File Closed for incompleteness",
    description: "File Closed for incompleteness",
  },
  {
    code: "Preapproved request denied by FI",
    description: "Preapproved request denied by FI",
  },
  {
    code: "Request Approved but not accpeted",
    description: "Request Approved but not accpeted",
  },
  {
    code: "Three day right of recession (Right to Rescind)",
    description: "Three day right of recession (Right to Rescind)",
  },
  { code: "Other", description: "Other" },
];

export const StewartConstants: StewartConstantsProps = {
  StewartCompanyID: "STW987654321",
  ClientReferenceNumber: "ClientReferenceNumber",
  DocumentList: "DocumentList",
  DocumentReferenceNumberPrefix: "Stewart",
  SaveLoginInformation: "SaveLoginInformation",
  LoginUserName: "LoginUserName",
  // LoginPassword: "LoginPassword",
  IV: "IV",
  IV_Value: "hOrSLFl3Wgqf2xB+58tqow==",
  StatusList: "StatusList",
  StewartContextID: "StewartContextID",
  IsCancelled: "IsCancelled",
  Success: "success",
  TestUser: "testuser01",
  SelectedAgentID: "SelectedAgentID",
  SelectedOfficeName: "SelectedOfficeName",
  SelectedOfficeAddress1: "SelectedOfficeAddress1",
  SelectedOfficeAddress2: "SelectedOfficeAddress2",
  SelectedOfficeCity: "SelectedOfficeAddressCity",
  SelectedOfficeState: "SelectedOfficeAddressState",
  SelectedOfficePostalCode: "SelectedOfficeAddressPostalCode",
  SelectedOfficePhone: "SelectedOfficePhone",
  SelectedOfficeEmailAdress: "SelectedOfficeEmailAddress",
  IsOfficeAddressSaved: "IsOfficeAddressSaved",
  LenderContractID: "LenderContractID",
  IsCTS: "IsCTS",
  MessageList: "MessageList",
  PayoffList: "PayoffList",
  StewartFileNumber: "StewartFileNumber",
  FNCFileNumber: "FNCFileNumber",
  StewartRateCalculator_LenderRole: 1,
  StewartRateCalculator_LoanEstimate: 3,
  RecordingFeesLineNumber: "1202",
  TransferTaxesLineNumber: "1203",
  CityCountyTransferTaxesLineNumber: "1204",
  StateTransferTaxesLineNumber: "1205",
  AdditionalTransferTaxesLineNumber: "1206",
  AdditionalTransferTaxesSecondLineNumber: "1207",
  AdditionalTransferTaxesThirdLineNumber: "1208",
  AdditionalTransferTaxesFourthLineNumber: "1209",
  RecordingFeeDeedDescription: "Deed - Recording Fees",
  RecordingFeeMortgageDescription: "Mortgage - Recording Fees",
  RecordingFeeReleaseDescription: "Release - Recording Fees",
  CityTransferTaxesDeedDescription: "Deed - City Transfer Tax",
  CityTransferTaxesMortgageDescription: "Mortgage - City Transfer Tax",
  CountyTransferTaxesDeedDescription: "Deed - County Transfer Tax",
  CountyTransferTaxesMortgageDescription: "Mortgage - County Transfer Tax",
  StateTransferTaxesDeedDescription: "Deed - State Transfer Tax",
  StateTransferTaxesMortgageDescription: "Mortgage - State Transfer Tax",
  FLAdditionalTransferTaxesMortgageDescription: "Mortgage - Intangible Tax",
  MNAdditionalTransferTaxesMortgageDescription: "Mortgage - Conservation Fund",
  MNAdditionalTransferTaxesDeedDescription: "Deed - Conservation Fund",
  MDAdditionalTransferTaxesDeedDescription: "Deed Recordation Tax - State",
  MDAdditionalTransferTaxesMortgageDescription:
    "Mortgage Recordation Tax - State",
  VAAdditionalTransferTaxesGrantorStateDescription: "Grantor - State",
  VAAdditionalTransferTaxesGrantorCountyDescription: "Grantor - County",
  VAAdditionalTransferTaxesGrantorCityDescription: "Grantor - City",
  VAAdditionalTransferTaxesGranteeStateDescription: "Grantee - State",
  VAAdditionalTransferTaxesGranteeCountyDescription: "Grantee - County",
  VAAdditionalTransferTaxesGranteeCityDescription: "Grantee - City",
  SettlementFeeDescription: "Settlement Fee",
  ClosingFeeDescription: "Closing Fee",
  EscrowFeeDescription: "Escrow Fee",
  ClosingProtectionLetterDescription: "Closing Protection Letter",
  CPLDescription: "CPL",
  InsuredClosingLetterDescription: "Insured Closing Letter",
  CAAffordableHousingFeeDescription:
    "Title S B2 Affordable Housing Recording Fee",
  TotalSettlementServiceProviderListItems: 10,
  PendingPlaceOrderStatus: "Pending (Place Order)",
  PendingFileNumberStatus: "Pending (Escrow File Number)",
  SuccessStatus: "Success",
  OrderConfirmedStatus: "Order Confirmed",
  Yes: "Y",
  PayoffTypeDemand: "Demand",
  PayoffTypeSubordination: "Subordination",
  CTSOfficeHeading: "Stewart Centralized Title Services",
  FNC_ORDER_NOTE_SUBJECT: "FNC FILE NUMBER:",
} as const;

export const requiredFieldIds: string[] = [
  "loanNumber",
  "baseLoanAmount",
  "loanPurpose",
  "purchasePriceAmount",
  "propertyStreet",
  "propertyCity",
  "propertyState",
  "propertyZipCode",
  "borrowerFirstName",
  "borrowerLastName",
  "borrowerAddress",
  "borrowerCity",
  "borrowerState",
  "borrowerZipCode",
];

export const coBorrowerFieldIds: string[] = [
  "coBorrowerFirstName",
  "coBorrowerMiddleName",
  "coBorrowerLastName",
  "coBorrowerSuffix",
  "coBorrowerPhoneNumber",
  "coBorrowerWorkNumber",
  "coBorrowerCellNumber",
  "coBorrowerEmailAddress",
  "coBorrowerMaritalStatus",
  "coBorrowerAddress",
  "coBorrowerCity",
  "coBorrowerState",
  "coBorrowerZipCode",
];
export const RichTexts: Record<string, string> = {
  orderprocessing: `Thank you for your request. We will begin processing your order shortly.

    Click ‘Get Order Updates’ later to see your file number and/or receive updates to the order. 
    If you do not receive your file number within 2 - 4 hours, contact the selected office or Stewart Customer Care at (855)330-2800 for assistance.`,
  SubmissionResultsRichTextBox:
    "Welcome back! Please Click the 'Get Order Updates' button to receive status, document, and message updates.",

  PendingNotes: "Pending message will be sent when Place Order is selected.",
  MessageSent: "Message has been sent.",
  cancelOrder:
    "Your cancellation request has been submitted. Please visit the Status tab for updates.",
} as const;
export const RadioConsts: Record<string, any> = {
  isHelocRadios: ["Insured", "UnInsured"],
  isConstruction: ["Purchase", "Refinance"],
  isReverseOrder: ["Reverse"],
  isSigningOnlyOrder: ["Signing Only"],
} as const;

export const borrowerJsonPaths: string[] = [
  "$.applications[0].borrower.residences[?(@.residencyType == 'Current')][0].addressStreetLine1",
  "$.applications[0].borrower.residences[?(@.residencyType == 'Current')][0].addressCity",
  "$.applications[0].borrower.residences[?(@.residencyType == 'Current')][0].addressState",
  "$.applications[0].borrower.residences[?(@.residencyType == 'Current')][0].addressPostalCode",
];
export const coBorrowerJsonPaths: string[] = [
  "$.applications[0].coborrower.residences[?(@.residencyType == 'Current')][0].addressStreetLine1",
  "$.applications[0].coborrower.residences[?(@.residencyType == 'Current')][0].addressCity",
  "$.applications[0].coborrower.residences[?(@.residencyType == 'Current')][0].addressState",
  "$.applications[0].coborrower.residences[?(@.residencyType == 'Current')][0].addressPostalCode",
];

export const documentBodyTableCols: string[] = [
  "",
  "Name",
  "Date",
  "Status",
  "Click to View",
  "Download",
];

export const payOffGridCols: string[] = [
  "",
  "Type",
  "Lender Name",
  "Lender Phone/Fax",
  "Loan Amount",
  "Loan Number",
];

export const escrowContacts = {
  city: "",
  phone: "",
  state: "",
  address: "",
  postalCode: "",
  contactType: "ESCROW_COMPANY",
  referenceNumber: "",
};

export const titleContacts = {
  city: "",
  phone: "",
  state: "",
  address: "",
  postalCode: "",
  contactType: "TITLE_COMPANY",
  referenceNumber: "",
};

export const API_URL = process.env.REACT_APP_ECP_API_URL as string;
