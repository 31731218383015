import { ThemeOptions } from '@mui/material';

import palette from './palette';
import spacing from './spacing';
import shadows from './shadows';
import breakpoints from './breakpoints';

// Extend ButtonPropsVariantOverrides to add custom variant 'contained'
declare module '@mui/material/Button' {

}
const mode = 'light';
// Define a light theme using ThemeOptions
const LIGHT_THEME: ThemeOptions = {
    palette: palette(mode),
    typography: {
        fontFamily: [
            'Inter',
            'sans-serif',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(','),
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
              @keyframes fade-in {
                from { opacity: 0; }
                to { opacity: 1; }
              }
            `
        },

    
    },
    shadows: shadows(mode),
    ...spacing,
    breakpoints: breakpoints,

    mixins: {
        toolbar: {
            minHeight: 64
        }
    }
    // Define responsive typography within the components object
};

export default LIGHT_THEME;
