import { createSlice } from "@reduxjs/toolkit";
import { fetchPolicyInsuredTypes } from "componnets/StewartSRC/utils";

 const policyInsuredTypesSlice = createSlice({
  name: 'policyInsuredTypes',
  initialState: {
    data: {},
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null
  },
  reducers: { },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPolicyInsuredTypes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPolicyInsuredTypes.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchPolicyInsuredTypes.rejected, (state) => {
        state.status = 'failed';
      
      });
  }
});

export default policyInsuredTypesSlice.reducer;