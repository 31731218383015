import type React from "react";
import Typography from "@mui/material/Typography";

interface MultitLineTypoprops {
  text?: string;
  hide?: boolean;
}
const MultiLineTypography: React.FC<MultitLineTypoprops> = ({ text = "" }) => {
  if (!text) return null;
  const lines = text.split("\n");

  return (
    <div style={{ minHeight: "100px" }}>
      {lines.map((line: string, index: number) => (
        <Typography key={index}>{line}</Typography>
      ))}
    </div>
  );
};

export default MultiLineTypography;
