// MUI Imports
import { Theme } from '@mui/material/styles';

const Typography = (theme: Theme) => {
  return {
    MuiTypography: {
      styleOverrides: {
        root: {
      
          fontSize: 14 
      
      },
    
        gutterBottom: {
          marginBottom: theme.spacing(2),
        },

        h1: {
          fontSize: '20px',
          fontWeight: 400, 
       
        },
        caption: {
          fontSize: '12px',
          fontWeight:400,
       
          color:'#212121'
         
        },
        header:{
       
          fontSize: '18px',
          fontWeight:600,
       
          color:'#212121'
        },
        rowText:{
          fontSize: '16px',
          fontWeight:400,
        
          color:'#212121'
        }
      },
    },
  };
};

export default Typography;
