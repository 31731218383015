import { useSelector } from "react-redux";
import { getTransactiondetailsData } from "./utils";
import { formatPropertyLocation } from "componnets/organisms/src/transactionDetails/utils";
import { formatCurrency } from "utils/srcutils";

const useTransactionDetails = () => {
  const {
    baseLoanAmount,
    purchasePriceAmount,
    city,
    county,
    propertyState,
    selectedTransObject,
  } = useSelector(getTransactiondetailsData);

  const transactionDetailsinitVal = {
    LoanAmount: formatCurrency(baseLoanAmount || 0),
    SalesPrice: formatCurrency(purchasePriceAmount || 0),
    transactionType: selectedTransObject?.Description ?? "",
    propertyLocation: formatPropertyLocation(city, propertyState, county),
  };
  return transactionDetailsinitVal;
};

export default useTransactionDetails;
