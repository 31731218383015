import { apiConst } from "constants/apiConst";

const environment: string|undefined = process.env.REACT_APP_ENV;
const urlJson = environment ? apiConst.development : apiConst.development;

const getUrl = (mapurl: string): string => {
    return urlJson[mapurl] as string;
};

const substituteParams = (url: string, paramObj: Record<string, string>): string => {
    let urlNew = url;
    Object.entries(paramObj).map(([k, v]) => urlNew = urlNew.replace("{" + k + "}", v));
    return urlNew;
};

const serializeToQueryParams = (paramObj: Record<string, string>): string => {
    let str = "?";
    Object.entries(paramObj).map(([k, v]) => (str += k + "=" + v + "&"));
    return str.replace(/[&]$/, "");
};

const getFullUrl = (urlInfo: Record<string, any | string>, url: string) => {
    let fullUrl = getUrl(url);
    if (urlInfo.pathParams) {
        fullUrl = substituteParams(fullUrl, urlInfo.pathParams);
    }
    if (urlInfo.queryParams) {
        fullUrl += serializeToQueryParams(urlInfo.queryParams);
    }
    return fullUrl;
};


export { getUrl,
    getFullUrl,
    substituteParams,
    serializeToQueryParams };
