import { RootState } from "interfaces/rootState";

// Field Types (Can be expanded as needed)
type FieldType =
  | "Typography"
  | "RadioGroup"
  | "DropDown"
  | "Divider"
  | "Grid"
  | "Subfields"
  | "TextField";

// Variant Types (For Typography)
type TypographyVariant = "header" | "rowText" | "caption";

// Base Field Interface (Shared Properties)
export interface BaseField {
  name?: string;
  fieldType: FieldType;
  gridSize?: number;
  justifyContent?: string;
  headersAlign?: string;
}

// Specific Field Interfaces
interface TypographyField extends BaseField {
  name: string;
  fieldType: "Typography";
  label?: string; // Made label optional
  variant?: TypographyVariant;
  caption?: string | boolean; // Changed caption to optional string type
  defaultValue?: string; // Added optional defaultValue for "Typography"
}

interface RadioGroupField extends BaseField {
  fieldType: "RadioGroup";
  labelsSet: string[];
  defaultValue?: string;
  row?: boolean;
  name: string;
}

interface DropDownField extends BaseField {
  fieldType: "DropDown";
  label?: string; // Label is optional for dropdown with default values
  options?: any[];
  defaultValue?: string; // Optional for default values
}

interface DividerField extends BaseField {
  fieldType: "Divider";
}

interface TextField extends BaseField {
  fieldType: "TextField";
  label?: string;
  defaultValue?: string; // Optional for default values
}

// Grid Field (Uses other field types)
export interface GridField extends BaseField {
  fieldType: "Grid";
  headers: (TypographyField | DividerField)[];
  rows: (TypographyField | DropDownField | DividerField | TextField)[][];
}

// Subfields Field
export interface SubfieldsField extends BaseField {
  fieldType: "Subfields";
  subfields: (TypographyField | RadioGroupField)[];
}

// Overall Form Schema Type
export type FormSchema = (SubfieldsField | GridField)[];
export type WithoutSplitSchema = GridField[];

export const formWithSplit: FormSchema = [
  {
    fieldType: "Subfields",
    name: "subfieldsGroup",
    subfields: [
      {
        fieldType: "Typography",
        name: "simultaneousIssueLabel",
        label: "Is the purchase a Simultaneous Issue?",
        gridSize: 3.8,
        variant: "header",
      },
      {
        fieldType: "RadioGroup",
        labelsSet: ["Yes", "No"],
        defaultValue: "Yes",
        row: true,
        name: "simultaneousIssue",
        gridSize: 3,
      },
    ],
  },
  {
    fieldType: "Grid",
    name: "policyGrid",
    headers: [
      {
        fieldType: "Typography",
        name: "policyHeader",
        label: "Policy",
        gridSize: 1,
        variant: "header",
      },
      {
        fieldType: "Typography",
        name: "policyRateTypeHeader",
        label: "Policy/Rate Type",
        gridSize: 3,
        variant: "header",
      },
      {
        fieldType: "Typography",
        name: "buyerPercentageHeader",
        label: "Buyer %",
        gridSize: 1,
        variant: "header",
      },
      { fieldType: "Divider", name: "dividerHeader1", gridSize: 0.04 },
      {
        fieldType: "Typography",
        name: "buyerAmountHeader",
        label: "Buyer Amount",
        gridSize: 2,
        variant: "header",
      },
      {
        fieldType: "Typography",
        name: "sellerPercentageHeader",
        label: "Seller %",
        gridSize: 1,
        variant: "header",
      },
      { fieldType: "Divider", name: "dividerHeader2", gridSize: 0.04 },
      {
        fieldType: "Typography",
        name: "sellerAmountHeader",
        label: "Seller Amount",
        gridSize: 2,
        variant: "header",
      },
      {
        fieldType: "Typography",
        name: "totalHeader",
        label: "Total",
        gridSize: 1,
        variant: "header",
      },
    ],
    rows: [
      [
        {
          fieldType: "Typography",
          name: "ownersLabel",
          label: "Owner's:",
          gridSize: 1,
          variant: "rowText",
        },
        {
          name: "ownersBasicPolicy",
          fieldType: "DropDown",
          label: "Basic Policy",
          gridSize: 3,
          options: [
            // { Description: "Basic Policy", Code: "BASIC" },
            // { Description: "Second Policy", Code: "SECOND" },
          ],
        },
        {
          fieldType: "TextField",
          name: "OwnersBuyerSplitPercentage",
          defaultValue: "0",
          gridSize: 1,
        },
        { fieldType: "Divider", name: "dividerRow1", gridSize: 0.04 },
        {
          fieldType: "TextField",
          name: "OwnersBuyerAmount",
          defaultValue: "$0.00",
          gridSize: 2,
        },
        {
          fieldType: "TextField",
          name: "OwnersSellerSplitPercentage",
          defaultValue: "100",
          gridSize: 1,
        },
        { fieldType: "Divider", name: "dividerRow2", gridSize: 0.04 },
        {
          fieldType: "TextField",
          name: "OwnersSellerAmount",
          defaultValue: "$1,886.00",
          gridSize: 2,
        },
        {
          fieldType: "Typography",
          name: "ownersTotal",
          label: "$1,886.00",
          caption: "$232.00",
          gridSize: 1,
          variant: "rowText",
        },
      ],
      [
        {
          fieldType: "Typography",
          name: "tridLabel",
          label: "TRID",
          gridSize: 1,
          caption: true,
          variant: "caption",
        },
        {
          fieldType: "Typography",
          name: "tridPlaceholder",
          label: "",
          gridSize: 3,
        },
        {
          fieldType: "Typography",
          name: "TRIDBuyerSplitPercentage",
          defaultValue: "0",
          gridSize: 1,
        },
        {
          fieldType: "Typography",
          name: "TRIDSellerSplitPercentage",
          defaultValue: "100",
          gridSize: 1,
        },
        {
          fieldType: "Typography",
          name: "TRIDBuyerAmount",
          label: "$0.00",
          gridSize: 2,
          caption: true,
          variant: "caption",
        },
        {
          fieldType: "Typography",
          name: "TRIDSellerAmount",
          defaultValue: "$1,886.00",
          gridSize: 2,
        },
        {
          fieldType: "Typography",
          name: "TRIDTotal",
          label: "$232.00",
          gridSize: 1,
          caption: true,
          variant: "caption",
        },
      ],
      [
        {
          fieldType: "Typography",
          name: "lendersLabel",
          label: "Lender's:",
          gridSize: 1,
          variant: "rowText",
        },
        {
          name: "lendersBasicPolicy",
          fieldType: "DropDown",
          label: "Basic Policy",
          gridSize: 3,
          options: [
            // { Description: "Basic Policy", Code: "BASIC" },
            // { Description: "Second Policy", Code: "SECOND" },
          ],
        },
        {
          fieldType: "TextField",
          name: "LendersBuyerSplitPercentage",
          defaultValue: "100",
          gridSize: 1,
        },
        { fieldType: "Divider", name: "dividerRow3", gridSize: 0.04 },
        {
          fieldType: "TextField",
          name: "LenderBuyerAmount",
          defaultValue: "$100.00",
          gridSize: 2,
        },
        {
          fieldType: "TextField",
          name: "LendersSellerSplitPercentage",
          defaultValue: "100",
          gridSize: 1,
        },
        { fieldType: "Divider", name: "dividerRow4", gridSize: 0.04 },
        {
          fieldType: "TextField",
          name: "LendersSellerAmount",
          defaultValue: "$0.00",
          gridSize: 2,
        },
        {
          fieldType: "Typography",
          name: "lendersTotal",
          label: "$100.00",
          caption: "$1,754.00",
          gridSize: 1,
          variant: "rowText",
        },
      ],
    ],
  },
];

export const withoutSplit: WithoutSplitSchema = [
  {
    fieldType: "Grid",
    headersAlign: "left",
    headers: [
      {
        fieldType: "Typography",
        label: "Policy",
        gridSize: 1,
        variant: "header",
        name: "policyHeader",
      },
      {
        fieldType: "Typography",
        label: "Policy/Rate Type",
        gridSize: 5,
        variant: "header",
        name: "policyRateTypeHeader",
      },
      {
        fieldType: "Typography",
        label: "Total",
        gridSize: 1,
        variant: "header",
        name: "totalHeader",
      },
    ],
    rows: [
      [
        {
          fieldType: "Typography",
          label: "Refi Loan:",
          gridSize: 1,
          variant: "rowText",
          justifyContent: "start",
          name: "refiLoanLabel",
        },
        {
          fieldType: "DropDown",
          name: "refiCoverageType",
          label: "Basic Policy",
          gridSize: 5,
          options: [
            // { Description: "Basic Policy", Code: "BASIC" },
            // { Description: "Second Policy", Code: "SECOND" },
          ],
          justifyContent: "start",
        },
        {
          fieldType: "Typography",
          label: "$1,886.00",
          caption: "$232.00",
          gridSize: 1,
          variant: "rowText",
          justifyContent: "start",
          name: "refiLoanTotal",
        },
      ],
    ],
  },
];

export const getInsurancePremiumData = (state: RootState) => {
  const {
    insurancePremim: { data = [] } = {},
    coverageTypes_OP: { data: coverageTypes_op = [] } = {},
    coverageTypes_MP: { data: coverageTypes_mp = [] } = {},
  } = state || {};

  return { data, coverageTypes_op, coverageTypes_mp };
};
