const StewartPartySuffixDescriptions: { [key: string]: string } = {
    "JR": "Jr.",
    "JR.": "Jr.",
    "SR": "Sr.",
    "SR.": "Sr.",
    "2ND": "2nd",
    "3RD": "3rd",
    "4TH": "4th",
    "II": "II",
    "III": "III",
    "IV": "IV"
};

export function getCanonicalSuffixName (suffixName: string): string {
    if (!suffixName) {
        return '';
    }

    const upperSuffixName = suffixName.toUpperCase();
    return StewartPartySuffixDescriptions[upperSuffixName] || '';
}
