// operationId.js
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TransactionDetail, transactionDetailsTemplate } from "componnets/organisms/src/transactionDetails/utils";

interface transactionDetailsTypes {
    data: { [key: string]: TransactionDetail }
}

const initialState: transactionDetailsTypes = {
    data:transactionDetailsTemplate
};

const transactionDetailsSlice = createSlice({
  name: "transactionDetails",
  initialState,
  reducers: {
    updateFieldVisibility: (state, action: PayloadAction<{ fieldName: string; hidden: boolean }>) => {
        const { fieldName, hidden } = action.payload;
        if (state.data?.[fieldName]) {
          state.data[fieldName] = { ...state.data[fieldName], hidden };
        }
      },
  },
});

export const { updateFieldVisibility } = transactionDetailsSlice.actions;
export default transactionDetailsSlice.reducer;
