import React, { useEffect } from "react";
import { RendertitleInsurancepremium } from "componnets/services/RenderForms";
import { useSelector, useDispatch } from "react-redux";
import { getInsurancePremiumData } from "./utils";
import { setData } from "redux/features/titleInsurancePremiumSlice";

export interface FormComponent {
  fieldType: string;
  label?: string;
  options?: string[];
  name?: string;
  defaultValue?: string;
  gridSize?: number;
  caption?: string;
  headers?: FormComponent[];
  rows?: FormComponent[][];
  subfields?: FormComponent[];
  hidden?: boolean;
}

const PremimumTitleInsurance: React.FC = () => {
  const dispatch = useDispatch();
  const { data, coverageTypes_op, coverageTypes_mp } = useSelector(
    getInsurancePremiumData
  );

  useEffect(() => {
    const updateFieldOptions = (
      rowName: string,
      options: any[],
      schema: any
    ) => {
      // Create a deep clone of the schema to avoid mutating the original
      const schemaCopy = JSON.parse(JSON.stringify(schema));

      schemaCopy.forEach((section: any) => {
        if (section.fieldType === "Grid") {
          section.rows.forEach((row: any) => {
            row.forEach((field: any) => {
              if (field.name === rowName) {
                field.options = options;
              }
            });
          });
        }
      });

      // console.log("hi Updated schema:", schemaCopy);
      // Here, you might want to set the new schemaCopy back to state or Redux
      dispatch(setData(schemaCopy));
    };

    if (data.length === 1) {
      // console.log("hi ", { data, coverageTypes_mp, coverageTypes_op });

      updateFieldOptions("refiCoverageType", coverageTypes_mp, data);
    } else if (coverageTypes_op.length) {
      updateFieldOptions("ownersBasicPolicy", coverageTypes_op, data);
    } else if (coverageTypes_mp.length) {
      updateFieldOptions("lendersBasicPolicy", coverageTypes_mp, data);
    }
  }, [coverageTypes_op, coverageTypes_mp]);

  return <RendertitleInsurancepremium titleInsurancepremium={data} />;
};

export default PremimumTitleInsurance;
