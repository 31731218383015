// ** MUI Imports
import { Theme } from "@mui/material/styles";

const TextField = (theme: Theme) => {
  return {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.customColors.focused,
          "&.Mui-focused": {
            color: theme.palette.customColors.focused,
          },
          "&:hover": {
            color: theme.palette.customColors.focused,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.customColors.focused,
          "&.Mui-focused": {
            color: theme.palette.customColors.focused,
          },
          "&:hover": {
            color: theme.palette.customColors.focused,
          },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          padding: `${theme.spacing(1.875, 3)}`,
          "& fieldset": {
            borderColor: theme.palette.customColors.main, // Outline border color
            borderWidth: 1, // Outline border width
            borderRadius: 4,
          },
          "& .MuiOutlinedInput-notchedOutline legend": {
            fontSize: 18, // Legend (label) font size
          },
        },
        "& .MuiInputLabel-outlined.Mui-focused, &:hover .MuiInputLabel-outlined":
          {
            color: theme.palette.customColors.main, // Label color on focus and hover
          },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            borderColor: theme.palette.customColors.main, // Border color on focus and hover
          },
        " & .MuiInputLabel-outlined": {
          color: theme.palette.common.black, // Default label color
        },
      },
    },
  };
};

export default TextField;
