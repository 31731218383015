import React, { Suspense, Profiler, ProfilerOnRenderCallback } from "react";
import ReactDOM from "react-dom/client";
import host from "@elliemae/em-ssf-guest";
import { AppProvider } from "providers";
import reportWebVitals from "./reportWebVitals";
import FullScreenLoader from "componnets/Loader";

const App = React.lazy(() => import("./App"));

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

host.connect();

// Define the type explicitly for onRenderCallback
type ProfilerCallbackType = (
  id: string,
  phase: "mount" | "update",
  actualDuration: number,
  baseDuration: number,
  startTime: number,
  commitTime: number,
  interactions: Set<any>
) => void;

// Declare onRenderCallback with the defined type
const onRenderCallback: ProfilerCallbackType = (
  id,
  phase,
  actualDuration,
  baseDuration,
  startTime,
  commitTime,
  interactions
) => {
  // console.log("Profiler info:", {
  //   id,
  //   phase,
  //   actualDuration,
  //   baseDuration,
  //   startTime,
  //   commitTime,
  //   interactions,
  // });
};

root.render(
  <Profiler
    id="AppRoot"
    onRender={onRenderCallback as ProfilerOnRenderCallback}
  >
    <AppProvider>
      <Suspense fallback={<FullScreenLoader />}>
        <App />
      </Suspense>
    </AppProvider>
  </Profiler>
);

reportWebVitals();
