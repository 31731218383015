// loader.js
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LoaderState {
  loading: boolean;
}

const initialState: LoaderState = {
  loading: false,
};

const loader = createSlice({
  name: "loader",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<Record<string, any>>) => {
      state.loading = action.payload.loading;
    },
  },
});

export const { setLoading } = loader.actions;
export default loader.reducer;
