import Header from "componnets/organisms/header";
import type React from "react";
import Box from "@mui/material/Box";
import { RootState } from "interfaces/rootState";
import { useSelector } from "react-redux";
import FullScreenLoader from "componnets/Loader";

interface mainLayoutProps {
  children: React.ReactNode;
  sx?: any;
}

const styles = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "auto",
};

const mainLayout: React.FC<mainLayoutProps> = ({ children, sx }) => {
  const { loading } = useSelector((state: RootState) => state.loader);
  return (
    <Box>
      <Box sx={styles}>
        <Header />
      </Box>
      <Box sx={{ ...styles, gap: 5, ...sx }}>
        {loading && <FullScreenLoader />}
        {children}
      </Box>
    </Box>
  );
};

export default mainLayout;
