import React from 'react';
import { Typography as MUITypography, Box, SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';

interface TypographyProps {
  label?: string;
  children?: React.ReactNode;
  gridSize?: number;
  variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2" | "body1" | "body2" | "caption" | "button" | "overline" | "inherit";
  sx?: SxProps<Theme>;
  isGrid?: boolean;
  justifyContent?:string
}

const Typography: React.FC<TypographyProps> = ({ label, isGrid=false, variant = "h1", children, sx,justifyContent='end' }) => {
  const gridStyle = {
                  display: 'flex',
                  justifyContent: justifyContent,
                  width: '100%',
                  wordBreak: "break-word",
                  whiteSpace: 'normal' ,

  };

  return (
    <Box sx={isGrid ? gridStyle : {}}>
      <MUITypography variant={variant} sx={sx} align='right'>
        {label}
        {children}
      </MUITypography>
    </Box>
  );
};

export default Typography;
