/* eslint-disable quote-props */
// ** MUI Imports
import { Theme } from '@mui/material/styles';

const Table = (theme: Theme) => {
    return {
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    // verticalAlign: 'baseline'
                    // boxShadow: theme.shadows[0],
                    
                }
            }
        },
     
    
        MuiTableRow: {
            styleOverrides: {
                root: {

                    // verticalAlign: 'top'

                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    '& .MuiButton-root': {
                        textTransform: 'uppercase',
                        color: theme.palette.text.secondary

                    },
                    minWidth: theme.spacing(25),
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    maxWidth: theme.spacing(40),
                    verticalAlign:'center'

                }
              
            }
        }
    };
};

export default Table;
