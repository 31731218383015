import { RootState } from "interfaces/rootState";

export const getTransactiondetailsData = (state: RootState) => {
  const {
    loan: {
      data: {
        loan: {
          baseLoanAmount = "",
          purchasePriceAmount = "",
          property: { city = "", state: propertyState = "", county = "" } = {},
        } = {},
      } = {},
    } = {},

    src: { selectedTransObject = {} },
  } = state || {};
  return {
    purchasePriceAmount,
    baseLoanAmount,
    propertyState,
    city,
    county,
    selectedTransObject,
  };
};

export const getTitleInsuranceDetailsData = (state: RootState) => {
  const { stateSettings: { data: { Splits: { Split = [] } = [] } = {} } = {} } =
    state || {};
  return {
    Split,
  };
};
