import { EncompassConstants, RadioConsts } from "constants/strings";

// import { createBorroweradress } from "utils/loanValidation";

function calculateLoanPurpose(
  loanPurposeType: string,
  selectedLoanType: string
): string {
  const isConstructionPurchase =
    selectedLoanType === EncompassConstants.Purchase;
  if (loanPurposeType === EncompassConstants.Construction) {
    return isConstructionPurchase
      ? `${EncompassConstants.Construction}--Purchase`
      : `${EncompassConstants.Construction}--Refinance`;
  } else {
    return loanPurposeType;
  }
}
interface FormValues {
  Subject: string;
  Body: string;
  Status: string;
  User?: string;
}
interface Contact {
  email?: string;
  contactType?: string;
  phone?: string;
  name?: string;
  contactName?: string;
  state?: string;
  address?: string;
  city?: string;
  referenceNumber?: string;
  postalCode?: string;
  fax?: string;
}

export function createNotesparams(
  Notes: FormValues[],
  closingDocument: any = {}
): { subject: string; Message: string }[] {
  const Notesarr = [];
  if (
    Notes.some((cv) => {
      return cv.Status === "Pending (Place Order)";
    }) ||
    Object.keys(closingDocument).length
  ) {
    Notesarr.push({ subject: "New Order", Message: "" });
  }
  Notes.forEach((cv) => {
    if (cv.Status === "Pending (Place Order)") {
      const { Subject, Body } = cv;

      Notesarr.push({ subject: Subject, Message: Body });
    }
  });

  return Notesarr;
}

export function updateNotesparams(
  Notes: FormValues[] = [],
  clientFileNumber: string = "",
  isorderPlaced: boolean,
  Address: string
): { Subject: string; Body: string; Status?: string }[] {
  return Notes.map((note) => {
    if (
      ["Pending (Place Order)", "Pending (Escrow File Number)"].includes(
        note.Status
      )
    ) {
      return {
        ...note,
        Status: clientFileNumber.length
          ? "Success"
          : isorderPlaced
          ? "Pending (Escrow File Number)"
          : "Pending (Place Order)",
        User: note.User || Address,
      };
    }

    return note;
  });
}

export function updateDocuments(
  documents: any[],
  clientFileNumber: string = "",
  isorderPlaced: boolean
) {
  return documents.map((d) => ({
    DOCUMENT: {
      ...d.DOCUMENT,
      Status: clientFileNumber.length
        ? "Success"
        : isorderPlaced
        ? "Pending (Escrow File Number)"
        : "Pending (Place Order)",
    },
  }));
}

function processLoanDetails(
  loanPurpose: string = "",
  isReverseOrder: boolean,
  isHeloc: string = "",
  mortgageType: string = "",
  lienType: string = "",
  isSigningOnlyOrder: boolean
): Record<string, string | boolean> {
  const Loan: Record<string, string | boolean> = {};
  if (
    loanPurpose !== EncompassConstants.Construction &&
    loanPurpose !== EncompassConstants.ConstructionToPermanent
  ) {
    if (isSigningOnlyOrder) {
      Loan.MortgageType = "SIGNINGONLY";
    } else if (
      mortgageType.localeCompare("Other", undefined, {
        sensitivity: "base",
      }) === 0 &&
      isReverseOrder
    ) {
      Loan.MortgageType = "REVERSE";
    }

    if (
      mortgageType === "HELOC" ||
      (lienType.toLowerCase() === "secondlien" &&
        loanPurpose === EncompassConstants.CashOutRefinance)
    ) {
      Loan.MortgageType = "HELOC";

      if (isHeloc === "HELOCInsured") {
        Loan.HelocInsured = true;
      } else if (isHeloc === "HELOCUnInsured") {
        Loan.HelocInsured = false;
      }
    }
  }
  return Loan;
}


export function canPlaceOrder(personas: string[], HidePlaceOrderPersona: string[]): boolean {
  for (let persona of personas) {
    if (HidePlaceOrderPersona.includes(persona)) {
      return true;
    }
  }
return false;
}

export function determineHelocValue(selectedLoanType: string): string {
  switch (selectedLoanType) {
    case RadioConsts.isHelocRadios[0]:
      return "HELOCInsured";
    case RadioConsts.isHelocRadios[1]:
      return "HELOCUnInsured";
    default:
      return "OtherThanHELOC";
  }
}
function findLenderInvestor(contacts: Contact[] = []): Contact | undefined {
  return contacts.find((contact) => contact.contactType === "LENDER_INVESTOR");
}
export const createLoanparams = (
  Data: Record<string, any> = {},
  isHeloc: string,
  selectedLoanType: string
): Record<string, any> => {
  const { loan = {}, product = {}, originatingParty={}} = Data ?? {};
  if (!Object.keys(loan).length) return {};

  const { pointOfContact = {} } = originatingParty;
  const { name: userName } = pointOfContact;
  const { partnerId = "" } = product;
  const clientID = `30${partnerId}`;
  // eslint-disable-next-line max-len
  const {
    applications = [],
    baseLoanAmount = "",
    loanProductData = {},
    mortgageType: MortgageType = "",
    originationDate = "",
    property = {},
    purchasePriceAmount = "",
    lenderCaseIdentifier: clientFileNumber = "",
    loanNumber = "",
    fhaVaLoan = {},
    contacts,
  } = loan;
  const lenderInvestor = findLenderInvestor(contacts) ?? {};
  const {
    email: lenderEmailAddress,
    name: lenderClientName,
    phone: lenderPhoneNumber,
    fax: lenderFaxNumber,
  } = lenderInvestor;

  const { lienPriorityType, loanScheduledClosingDate } = loanProductData;

  const { closingDate } = fhaVaLoan;

  // eslint-disable-next-line max-len
  const {
    loanPurposeType = "",
    city: propertyCity,
    county: propertyCounty,
    postalCode: propertyZipCode,
    state: propertyState,
    streetAddress: propertyStreet,
    legalDescriptionText1: propertyLegalDescription,
    assessorsParcelIdentifier: ParcelID,
  } = property ?? {};

  const { borrower = {}, coborrower = {} } = applications?.[0] ?? {};

  // eslint-disable-next-line max-len
  const {
    emailAddressText: borrowerEmailAddress,
    firstName: borrowerFirstName,
    lastName: borrowerLastName,
    suffixToName: borrowerSuffix,
    middleName: borrowerMiddleName,
    mobilePhone: borrowerCellNumber,
    homePhoneNumber: borrowerPhoneNumber,
    employment: borrowerEmployment = [],
    residences: BorrowerResidence = [],
    maritalStatusType: borrowerMaritalStatus = "",
    mailingAddress: borrowerMailingAdress = {},
    taxIdentificationIdentifier: borrowerTaxPayerIdentification,
  } = borrower;
  const { phoneNumber: borrowerWorkNumber } = borrowerEmployment?.[0] ?? [];

  const {
    addressStreetLine1: mailingAddress,
    addressCity: mailingCity,
    addressState: mailingState,
    addressPostalCode: mailingZipCode,
  } = borrowerMailingAdress;

  const {
    addressCity: borrowerCity,
    addressPostalCode: borrowerZipCode,
    addressState: borrowerState,
    addressStreetLine1: borrowerAddress,
  } = BorrowerResidence?.[0] ?? {};

  // eslint-disable-next-line max-len
  const {
    emailAddressText: coBorrowerEmailAddress = "",
    firstName: coBorrowerFirstName = "",
    lastName: coBorrowerLastName = "",
    suffixToName: coBorrowerSuffix = "",
    middleName: coBorrowerMiddleName = "",
    mobilePhone: coBorrowerCellNumber = "",
    homePhoneNumber: coBorrowerPhoneNumber = "",
    employment: coBorrowerEmployment = [],
    residences: coBorrowerResidence = [],
    maritalStatusType: coBorrowerMaritalStatus = "",
    taxIdentificationIdentifier: coBorrowerTaxPayerIdentification = "",
  } = coborrower;

  const { phoneNumber: coBorrowerWorkNumber } = coBorrowerEmployment?.[0] ?? [];

  const {
    addressCity: coBorrowerCity,
    addressPostalCode: coBorrowerZipCode,
    addressState: coBorrowerState,
    addressStreetLine1: coBorrowerAddress,
  } = coBorrowerResidence?.[0] ?? {};

  const loanPurpose = calculateLoanPurpose(loanPurposeType, selectedLoanType);
  const chkReverse = selectedLoanType === "Reverse";
  const chkSigningOnly = selectedLoanType === "Signing Only";

  const Helioc = processLoanDetails(
    loanPurposeType,
    chkReverse,
    isHeloc,
    MortgageType,
    lienPriorityType,
    chkSigningOnly
  );
  return {
    clientID,
    baseLoanAmount,
    MortgageType,
    originationDate,
    purchasePriceAmount,
    clientFileNumber,
    loanNumber,
    lienPriorityType,
    loanScheduledClosingDate,
    closingDate,
    loanPurpose,
    loanPurposeType,
    borrowerEmailAddress,
    borrowerFirstName,
    borrowerLastName,
    borrowerSuffix,
    borrowerMiddleName,
    borrowerCellNumber,
    borrowerPhoneNumber,
    borrowerEmployment,
    borrowerMaritalStatus,
    coBorrowerTaxPayerIdentification,
    borrowerTaxPayerIdentification,
    mailingAddress,
    mailingCity,
    mailingState,
    mailingZipCode,
    borrowerCity,
    borrowerZipCode,
    borrowerState,
    borrowerAddress,
    coBorrowerEmailAddress,
    coBorrowerFirstName,
    coBorrowerLastName,
    coBorrowerSuffix,
    coBorrowerMiddleName,
    coBorrowerCellNumber,
    coBorrowerPhoneNumber,
    coBorrowerEmployment,
    coBorrowerWorkNumber,
    borrowerWorkNumber,
    coBorrowerMaritalStatus,
    coBorrowerCity,
    coBorrowerZipCode,
    coBorrowerState,
    coBorrowerAddress,
    propertyStreet,
    propertyCity,
    propertyState,
    propertyCounty,
    propertyZipCode,
    propertyLegalDescription,
    ParcelID,
    lenderEmailAddress,
    lenderFaxNumber,
    lenderPhoneNumber,
    lenderClientName,
    userName,
    ...Helioc,
  };
};

export function createUpdateMessage(updateInfo: any): string {
  let statusMessage;

  if (Array.isArray(updateInfo.Status)) {
    statusMessage = `${updateInfo.Status.length} status update${
      updateInfo.Status.length !== 1 ? "s" : ""
    }`;
  } else if (
    typeof updateInfo.Status === "object" &&
    updateInfo.Status !== null
  ) {
    statusMessage = ` 1 status update`;
  } else {
    statusMessage = "No status updates";
  }
  let documentMessage = updateInfo.rwDocs
    ? `${updateInfo.rwDocs.length} new document${
        updateInfo.rwDocs.length !== 1 ? "s" : ""
      }`
    : "No new documents";
  let noteMessage;
  if (Array.isArray(updateInfo.Note)) {
    const numberOfMessages = updateInfo.Note.length;
    noteMessage =
      numberOfMessages === 1
        ? "1 new message"
        : `${numberOfMessages} new messages`;
  } else if (typeof updateInfo.Note === "object" && updateInfo.Note !== null) {
    noteMessage = "1 new message ";
  } else {
    noteMessage = "No new messages";
  }

  let message = `The following updates were received:\n\n

${statusMessage}\n
${documentMessage}\n
${noteMessage}`;

  return message;
}
