import { createSlice } from "@reduxjs/toolkit";
import { fetchRecordings } from "componnets/StewartSRC/utils";

const recordingsSlice = createSlice({
  name: "recordings",
  initialState: {
    data: {},
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecordings.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRecordings.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchRecordings.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export default recordingsSlice.reducer;
