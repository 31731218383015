import { RenderTitleServiceFee } from "componnets/services/RenderForms";
import { FC, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "componnets/atoms/typography";
import ButtonLink from "componnets/atoms/hyperLink";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "interfaces/rootState";
import { ApiPathConsts } from "constants/strings";
import axiosEcpInstance from "lib/axiosEcp";
import { getFullUrl } from "utils/UrlMap";
import { setProviders, setSelectedProviders } from "redux/features/srcSlice";
import useRunOnceAfterValues from "hooks/useRunOnceAfterValue";
import EPCModal from "componnets/atoms/EpcModal";
import BusinessList from "../buisnessCard";

interface TitleServicefeeProps {
  selectOffice: boolean;
  setSelectoffice: any;
}

const formSchema: any[] = [
  {
    fieldType: "Grid",
    headers: [
      {
        fieldType: "Typography",
        label: "",
        gridSize: 3,
        variant: "header",
        name: "emptyHeader",
      },
      {
        fieldType: "Typography",
        label: "Buyer Amount",
        gridSize: 4,
        variant: "header",
        name: "buyerAmountHeader",
      },
      {
        fieldType: "Typography",
        label: "Seller Amount",
        gridSize: 4,
        variant: "header",
        name: "sellerAmountHeader",
      },
      {
        fieldType: "Typography",
        label: "Total",
        gridSize: 1.5,
        variant: "header",
        name: "totalHeader",
      },
    ],
    rows: [
      [
        {
          fieldType: "Typography",
          label: "Title Abstract Charge",
          gridSize: 3,
          variant: "rowText",
          name: "titleAbstractChargeLabel",
        },
        {
          fieldType: "TextField",
          name: "titleAbstractChargeBuyer",
          defaultValue: "265.00",
          gridSize: 4,
          variant: "rowText",
        },
        {
          fieldType: "TextField",
          name: "titleAbstractChargeSeller",
          defaultValue: "50.00",
          gridSize: 4,
          variant: "rowText",
        },
        {
          fieldType: "Typography",
          label: "$265.00",
          gridSize: 1.5,
          variant: "rowText",
          name: "titleAbstractChargeTotal",
        },
      ],
      [
        {
          fieldType: "Typography",
          label: "Title Closing Fee",
          gridSize: 3,
          variant: "rowText",
          name: "titleClosingFeeLabel",
        },
        {
          fieldType: "TextField",
          name: "titleClosingFeeBuyer",
          defaultValue: "230.00",
          gridSize: 4,
          variant: "rowText",
        },
        {
          fieldType: "TextField",
          name: "titleClosingFeeSeller",
          defaultValue: "495.00",
          gridSize: 4,
          variant: "rowText",
        },
        {
          fieldType: "Typography",
          label: "$725.00",
          gridSize: 1.5,
          variant: "rowText",
          name: "titleClosingFeeTotal",
        },
      ],
      [
        {
          fieldType: "Typography",
          label: "Title Examination Fee",
          gridSize: 3,
          variant: "rowText",
          name: "titleExaminationFeeLabel",
        },
        {
          fieldType: "TextField",
          name: "titleExaminationFeeBuyer",
          defaultValue: "400.00",
          gridSize: 4,
          variant: "rowText",
        },
        {
          fieldType: "TextField",
          name: "titleExaminationFeeSeller",
          defaultValue: "50.00",
          gridSize: 4,
          variant: "rowText",
        },
        {
          fieldType: "Typography",
          label: "$400.00",
          gridSize: 1.5,
          variant: "rowText",
          name: "titleExaminationFeeTotal",
        },
      ],
      [
        {
          fieldType: "Typography",
          label: "Total for Title Service Fees (pre-tax)",
          gridSize: 3,
          variant: "rowText",
          name: "totalTitleServiceFeesLabel",
        },
        {
          fieldType: "Typography",
          label: "$895.00",
          gridSize: 4,
          variant: "rowText",
          name: "totalTitleServiceFeesBuyer",
        },
        {
          fieldType: "Typography",
          label: "$495.00",
          gridSize: 4,
          variant: "rowText",
          name: "totalTitleServiceFeesSeller",
        },
        {
          fieldType: "Typography",
          label: "$1,390.00",
          gridSize: 1.5,
          variant: "rowText",
          name: "totalTitleServiceFeesTotal",
        },
      ],
    ],
  },
];

const TitleServiceFeeDetails: FC<TitleServicefeeProps> = ({
  selectOffice,
  setSelectoffice,
}) => {
  const loan = useSelector((state: RootState) => state.loan.data.loan) ?? {};
  const countyCode = useSelector(
    (state: RootState) => state.countyCode.countyCode
  );
  const transaction = useSelector(
    (state: RootState) => state.src.slectedTransaction
  );
  const data = useSelector((state: RootState) => state);
  console.log(data, "datatat");
  const selectedproviders = useSelector(
    (state: RootState) => state.src.selectedProviders
  );

  const selected = useSelector((state: RootState) => state.src.selected);

  const [filteredBusinessData, setFilteredBusinessData] = useState([]);

  const { property } = loan;
  const { city, state, postalCode } = property ?? {};
  const dispatch = useDispatch();

  const handleChangeOffice = async () => {
    try {
      const path = getFullUrl(
        {
          queryParams: {
            stateCode: state,
            countyCode: countyCode,
            transactionType: transaction ?? "SALE",
            city: city,
            ...(postalCode && { zipCode: postalCode }),
          },
        },
        ApiPathConsts.getProviders
      );
      const response = await axiosEcpInstance.get(path);

      const providers = response.data?.ProviderList?.Provider ?? [];
      const filteredProviders = providers.filter(
        (providers: { IsProviderWithFee: string }) =>
          providers.IsProviderWithFee === "true"
      );

      const sortedProviders = filteredProviders.sort(
        (
          a: { ProximityFromProperty: string },
          b: { ProximityFromProperty: string }
        ) =>
          parseFloat(a.ProximityFromProperty) -
          parseFloat(b.ProximityFromProperty)
      );

      dispatch(setProviders(sortedProviders));

      setFilteredBusinessData(providers);
      if (providers?.length) {
        dispatch(setSelectedProviders(providers[0]));
      }
    } catch (error) {
      return null;
    }
  };
  useRunOnceAfterValues(() => {
    handleChangeOffice();
    return () => console.log("test");
  }, [countyCode, state, city, transaction]);

  const handleProviderSelect = () => {
    const selectedLocation = filteredBusinessData[selected];
    dispatch(setSelectedProviders(selectedLocation));

    setSelectoffice(false);
  };

  return (
    <>
      <Box>
        {selectedproviders && Object.keys(selectedproviders).length > 0 && (
          <Typography variant="body2" sx={{ textAlign: "left", paddingX: 2 }}>
            {selectedproviders.Name}{" "}
            <ButtonLink
              label="change office"
              onClick={() => setSelectoffice(true)}
            />
            <br />
            {selectedproviders.Address}
            <br />
            Contact Name: {"To be done "}, Email: {"To be done "}, Tel:{" "}
            {selectedproviders.Phone}
          </Typography>
        )}
        <RenderTitleServiceFee titleServiceFee={formSchema} />
        <EPCModal
          open={selectOffice}
          onClose={() => setSelectoffice(false)}
          isHeader={false}
          showOkButton={false}
          width={"60%"}
          onContinue={handleProviderSelect}
          isProvidersHeader
        >
          <BusinessList
            handleProviderSelect={handleProviderSelect}
            onClose={() => setSelectoffice(false)}
            filteredBusinessData={filteredBusinessData}
            setFilteredBusinessData={setFilteredBusinessData}
          />
        </EPCModal>
      </Box>
    </>
  );
};

export default TitleServiceFeeDetails;
