import CustomAccordion from "componnets/molecules/Accordion";
import type { FC } from "react";
import TransactionDetailsUI from "./TransactionDetails";

interface TransactionDetailsUIProps {}

const TransactionDetails: FC<TransactionDetailsUIProps> = () => {

  return (
    <CustomAccordion summary="Transaction Details">
      <TransactionDetailsUI />
    </CustomAccordion>
  );
};

export default TransactionDetails;
