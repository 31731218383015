import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Box } from "@mui/material";
import { validateHeaderValue } from "http";

interface WithFieldsProps {
  label: string;
  required?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  isGrid?: boolean;
  size?: string;
  sx?: object;
  defaultValue?: string;
  fieldType: string;
  onChange: any;
  name: string;
}

export const withFields = <P extends object>(
  Component: React.FC<P>
): React.FC<P & WithFieldsProps> => {
  return (props: P & WithFieldsProps) => {
    const { getValues, register } = useFormContext();
    const { hidden, name } = props;
    const value = getValues(name);
    // console.log(props,'props')
    const enhancedProps = {
      ...props,
      value: value,
    };
    if (hidden) return null;

    return (
      <Box key={props?.name} sx={{ flex: 1 }}>
        <Component {...register(name)} {...enhancedProps} />
      </Box>
    );
  };
};
