import { RootState } from "interfaces/rootState";
import { GridField, SubfieldsField } from "../TitleInsurancepremium/utils";

export interface TransactionDetail {
  name: string;
  fieldType: "DropDown" | "TextField" | "Input" | "CurrencyTextField";
  label: string;
  required: boolean;
  disabled: boolean;
  hidden: boolean;
  [key: string]: any;
}

export interface PolicyInsuredTypes {
  Code: string;
  Description: string;
  AmountName: string;
}

interface FieldConfig {
  hidden: boolean;
}

interface AmountMapping {
  Code: string;
  Description: string;
  AmountName: keyof FieldConfig; // Ensure the AmountName aligns with field names
}

interface UpdatedFieldInfo {
  name: string;
  hidden: boolean;
}

type ConfigItem = SubfieldsField | GridField;

export const transactionDetailsTemplate: { [key: string]: TransactionDetail } =
  {
    propertyLocation: {
      name: "propertyLocation",
      fieldType: "TextField",
      label: "Property Location",
      required: false,
      disabled: true,
      hidden: false,
    },
    transactionType: {
      name: "transactionType",
      fieldType: "TextField",
      label: "Transaction Type",
      required: false,
      disabled: true,
      hidden: false,
    },
    MP: {
      name: "LoanAmount",
      fieldType: "CurrencyTextField",
      label: "Loan Amount",
      required: false,
      disabled: true,
      hidden: false,
    },
    OP: {
      name: "SalesPrice",
      fieldType: "CurrencyTextField",
      label: "Sales price",
      required: false,
      disabled: true,
      hidden: false,
    },
 
  };

export function formatPropertyLocation(
  city: string,
  state: string,
  county: string
) {
  if (city && state && county) {
    return (
      `${city || ""}${city ? ", " : ""}${state || ""}${state ? ", " : ""}${
        county || ""
      }` || ""
    );
  }
  return "";
}

export const getTransactionData = (state: RootState) => {
  const {
    loan: {
      data: { loan: { property: { loanPurposeType = "" } = {} } = {} } = {},
    } = {},

    policyInsuredTypes: {
      data: { PolicyInsuredTypeList: { PolicyInsuredType = [] } = {} } = {},
    } = {},
    transactionDetails: { data: transactionDetails = {} },
    src: { selectedTransObject = {} },
    transactionalTypes: { data: { transacTypes = [] } = {} } = {},
  } = state || {};
  return {
    transacTypes,
    selectedTransObject,
    loanPurposeType,
    PolicyInsuredType,
    transactionDetails,
  };
};

export function setTransactionDetailsFieldVisibility(
  fields: Record<string, FieldConfig>,
  amountMappings: AmountMapping[]
): UpdatedFieldInfo[] {
  const propertiesToCheck = ["MP", "OP"];
  const updatedFields: UpdatedFieldInfo[] = [];

  for (const fieldName of propertiesToCheck) {
    const field = fields[fieldName];

    if (field) {
      const hasMapping = amountMappings.some(
        (mapping) => mapping.Code === fieldName
      );

      const shouldHide = !hasMapping;

      if (field.hidden !== shouldHide) {
        updatedFields.push({ name: fieldName, hidden: shouldHide });
      }
    }
  }

  return updatedFields;
}
// Function to modify config based on the codes
export function modifyConfigBasedOnCodes(
  config: ConfigItem[],
  PolicyInsuredType: PolicyInsuredTypes[]
): ConfigItem[] {
  console.log(PolicyInsuredType, "config");

  if (!Array.isArray(PolicyInsuredType) || !PolicyInsuredType?.length)
    return config;

  const codes = PolicyInsuredType.map((item: PolicyInsuredTypes) => item.Code);
  return config.map((item) => {
    if (item.fieldType === "Grid") {
      let updatedRows = [...item.rows];

      if (!codes.includes("MP")) {
        updatedRows = updatedRows.filter(
          (row) => !row.some((cell: any) => cell.label === "Lender's:")
        );
      }

      if (!codes.includes("OP")) {
        updatedRows = updatedRows.filter(
          (row) => !row.some((cell: any) => cell.label === "Owner's")
        );
      }

      return { ...item, rows: updatedRows };
    }

    return item;
  });
}
