import { useEffect, FC } from "react";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

interface Props {
  message?: string;
  error?: Error;
}

// Initialize Application Insights instance
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: "11c4fcd5-d85d-46a4-a316-3d0a6e5f2756",
    // 0213e533-62aa-469e-9de8-06e1d1be4ed6 -- QA
  },
});
appInsights.loadAppInsights();

// Logging function for messages
export const logMessage = (message: string) => {
  appInsights.trackTrace({ message: message });
};

// Logging function for exceptions
export const logException = (message: string, error: any) => {
  // if (error?.error) {
  //   // Axios error with response
  //   const { error: axiosError } = error;
  //   axiosError.message = message;
  //   axiosError.operationId =
  //     appInsights?.context?.telemetryTrace?.traceID || "";
  //   const exceptionProperties: any = {
  //     exception: new Error(JSON.stringify(axiosError)),
  //   };

  //   if (axiosError.response) {
  //     exceptionProperties.status = axiosError.response.status;
  //     exceptionProperties.data = axiosError.response.data;
  //   }
  //   if (axiosError.config) {
  //     exceptionProperties.config = axiosError.config;
  //   }

  //   exceptionProperties.originator = message || "unknown";
  //   appInsights.trackException(exceptionProperties);
  //   store.dispatch(AddOperationIdInStore(axiosError.operationId));
  // } else {
  //   // Other types of errors
  //   appInsights.trackException({
  //     exception: new Error(
  //       error?.error ? JSON.stringify(error || {}) : message
  //     ),
  //   });
  // }
  return null;
};

const Logger: FC<Props> = ({ message = "Something went wrong", error }) => {
  useEffect(() => {
    if (message) {
      logMessage(message);
    }
    if (error) {
      logException(message, error);
    }
  }, [message, error]);

  return null;
};

export default Logger;
