import { useSelector } from "react-redux";
import { getTitleInsuranceDetailsData } from "./utils";

const useTitleInsuraceDetails = () => {
  const { Split } = useSelector(getTitleInsuranceDetailsData);

  return {
    OwnersBuyerSplitPercentage: Split?.[0]?.BuyerSplitDefault,
    OwnersSellerSplitPercentage: Split?.[0]?.SellerSplitDefault,
    LendersBuyerSplitPercentage: Split?.[1]?.BuyerSplitDefault,
    LendersSellerSplitPercentage: Split?.[1]?.SellerSplitDefault,
  };
};

export default useTitleInsuraceDetails;
