import {
  ApiPathConsts,
  EncompassConstants,
} from "constants/strings";
import host from "@elliemae/em-ssf-guest";
import axios from "axios";
import axiosEcpInstance from "lib/axiosEcp";

import { getFullUrl, getUrl } from "./UrlMap";

import { logException } from "componnets/atoms/Logger";

export function formatCurrency(
  amount: string,
  currency = "USD",
  locale = "en-US"
): string {

  console.log(amount)
  if (!amount) return "";
  const cleanedAmount = amount.toString().replace(/[^0-9.-]+/g, ""); 

  const amt = parseFloat(cleanedAmount); 

  const formattedAmount = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  }).format(amt);

  return formattedAmount;
}
export const getValueOrDefault = (
  params: Record<string, any>,
  key: string,
  defaultValue: any = ""
): any => {
  return params.hasOwnProperty(key) ? params[key] : defaultValue;
};
export function concatenateWithSpaces<T>(items: T[]): string {
  return items.join(" ");
}

export const preparePropertyStreet = (
  street: string,
  urlaStreet: string,
  urlaUnit: string
): string => {
  if (urlaStreet) {
    return urlaUnit ? `${urlaStreet} ${urlaUnit}` : urlaStreet;
  }

  return street || "";
};
export const concatenateAddress = (
  street: string | undefined,
  city: string | undefined,
  state: string | undefined,
  zip: string | undefined
): string => {
  let address = street || "";

  if (address) {
    address += "\n";
  }

  if (city) {
    address += state ? `${city}, ${state} ${zip || ""}` : city;
  }

  return address;
};

export function mimeTypeMapper(type: string) {
  switch (type.toLowerCase()) {
    case ".doc":
    case ".docx":
      // return "application/msword"; // You may adjust this MIME type based on your needs
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

    case ".pdf":
      return "application/pdf";

    case ".xls":
    case ".xlsx":
      return "application/vnd.ms-excel";

    case ".xml":
      return "application/xml";

    case ".tif":
      return "image/tiff";

    case ".jpg":
    case ".jpeg":
      return "image/jpeg";

    case ".bmp":
      return "image/bmp";

    case ".png":
      return "image/png";

    case ".txt":
      return "text/plain";

    default:
      console.error("Unsupported file type");
      return ""; // You can choose another default value if needed
  }
}

export function base64ToUint8Array(base64: string) {
  // Decode Base64
  const binaryString = atob(base64);
  //Convert decoded content to unit8Array
  return Uint8Array.from([...binaryString].map((char) => char.charCodeAt(0)));
}

export function base64ToBlob(base64Str: string, fileType: string) {
  const uint8ArrayContent = base64ToUint8Array(base64Str);

  // Create Blob
  const blob = new Blob([uint8ArrayContent], {
    type: mimeTypeMapper(fileType),
  });

  // Create Blob URL
  const blobUrl = URL.createObjectURL(blob);

  // Now 'blobUrl' can be used to display or download the PDF
  return blobUrl;
}

export function readFileContent(file: any, type: string) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    type === "buffer"
      ? reader.readAsArrayBuffer(file)
      : reader.readAsDataURL(file);
  });
}
/**
 *
 * @param selectedResource is a Input File Object , basically from a file picker
 * @param epcHost
 * @returns
 */
export async function createResourceOnEpc(selectedResource: any, epcHost: any) {
  try {
    const transactionObject = await epcHost.getObject("transaction");
    const fileContent: any = await readFileContent(selectedResource, "buffer");

    // Step 1: Create an EPC resource
    const resourceData = await transactionObject.createResource({
      name: selectedResource.name,
      content: fileContent.split(",")[1],
    });

    if (resourceData.id) {
      // Step 2: Stream the file to the resource location
      try {
        const { data } = await axios.put(
          resourceData.location,
          selectedResource,
          {
            headers: {
              "Content-Type": resourceData?.mimeType,
              Authorization: resourceData.authorizationHeader,
            },
          }
        );

        const response = await axios(resourceData.location, {
          headers: {
            Authorization: resourceData.authorizationHeader,
          },
          responseType: "blob",
        });
        // Create a Blob URL and return it
        const blobUrl = URL.createObjectURL(
          new Blob([response.data], { type: response.headers["content-type"] })
        );
        return blobUrl;
      } catch (error) {
        // Handle errors here
        console.error(error);
        logException("Err streaming file to epc [utils helper]", {
          error: error || {},
          message: "Err streaming file to epc [utils helper]",
        });
        return "";
      }
    }

    // Upon success - commit the resource to the transaction request
    // applicationState.transactionRequest.resources.push({
    //   id: resourceData.id,
    //   name: resourceData.name,
    //   mimeType: selectedResource.type,
    // });
  } catch (error) {
    console.error({ error });
    logException("Err at createResourceOnEpc [utils helper]", {
      error: error || {},
      message: "Err at createResourceOnEpc [utils helper]",
    });
    return "";
  }
}
export async function createResourceOnEpcForRwFiles(
  epcHost: any,
  rwDocs: { content: string; filename: string; mimeType: string }[],
  txnId: string,
  docFolderArr: { DocIdentifier: string; FolderName: string }[],
  doFolderMapping: boolean
) {
  // Step 1: Prepare data for the saveToEFolder API call
  const resourceDataArray = await Promise.all(
    rwDocs.map(async (rwDoc) => {
      const uint8ArrayContent = base64ToUint8Array(rwDoc.content);
      const blob = new Blob([uint8ArrayContent], { type: rwDoc.mimeType });
      const file = new File([blob], rwDoc.filename, { type: blob.type });

      const fileNameWOExt = rwDoc.filename.split(".").at(0) || "";
      //----------------- EFolder mapping w.r.t lender setting ---------------//
      // const folderName =
      //   docFolderArr.find(
      //     (x) =>
      //       x.DocIdentifier.toLowerCase() ===
      //       fileNameWOExt.replace(" ", "_").toLowerCase()
      //   )?.FolderName || fileNameWOExt;
      //----------------------------------------------------------------------//
      const fileContent = await readFileContent(file, "buffer");

      return {
        name: rwDoc.filename,
        mimeType: rwDoc.mimeType,
        type: doFolderMapping ? fileNameWOExt : "Outgoing",
        fileContent: fileContent,
      };
    })
  );

  // Step 2: Call the saveToEFolder API once with all document data
  const { data: resourceData } = await axiosEcpInstance.post(
    `saveToEFolder/${txnId}`,
    resourceDataArray.map((item) => ({
      name: item.name,
      mimeType: item.mimeType,
      type: item.type,
    }))
  );

  // Step 3: Process the response and upload file content
  const resultPromises = resourceData?.data?.map(
    async (resourceDataItem: any, index: number) => {
      const fileContent = resourceDataArray[index].fileContent;

      // Stream the file content to the resource location
      const result = await axiosEcpInstance.put(
        resourceDataItem.location,
        fileContent,
        {
          headers: {
            "Content-Type": resourceDataItem.mimeType,
            Authorization: resourceDataItem.authorization,
          },
        }
      );

      console.log(`RW File ${resourceDataItem.name} pushed to EPC :-`, {
        resourceDataItem,
        result,
      });
    }
  );

  await Promise.all(resultPromises);

  return resourceData.data;
}

export function formatDateFromMS(ms: number) {
  return new Intl.DateTimeFormat("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }).format(new Date(ms));
}

export function formatServerDateToLocal(date: any) {
  const createDate = new Date(date);

  // Obtain user's timezone offset in minutes
  const userTimezoneOffset = new Date().getTimezoneOffset() - 60; // TODO: added -60 due to DST, handle this with library, when DST is off remove -60

  // Calculate the time difference in milliseconds
  const timeDifference = userTimezoneOffset * 60 * 1000;

  // Determine the +/- sign based on the offset
  const adjustedDate = new Date(
    createDate.getTime() +
      (userTimezoneOffset >= 0 ? -timeDifference : timeDifference)
  );

  // Format the adjusted date without GMT offset and timezone abbreviation
  const formattedDate = adjustedDate.toLocaleString("en-US", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  });

  return formattedDate;
}

export function formatCurrentDate() {
  const currentDate = new Date();

  const formattedDate = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  }).format(currentDate);

  return formattedDate;
}

export function updateDocumentStatus(orderPlaced: boolean, escrowNum: string) {
  return orderPlaced && escrowNum
    ? "Success"
    : orderPlaced && !escrowNum
    ? "Pending (Escrow File Number)"
    : "Pending (Place Order)";
}

type EncompassError = {
  Encompass: {
    Header: {
      ContextId: string;
    };
    Errors: {
      Error: {
        Message: string;
      };
    };
  };
};

export function getErrorMessage(jsonData: EncompassError): string {
  return jsonData.Encompass?.Errors?.Error?.Message ?? "";
}

export async function pullEpcResourcesByTxnId(id: string) {
  if (!id) return;
  const url = getFullUrl(
    { pathParams: { transaction_id: id } },
    "pullDocsFromEpc"
  );
  try {
    const res = await axiosEcpInstance.get(url);
    console.log("Docs pulled from epc using txId", res.data);
    return res.data;
  } catch (error) {
    console.error("Err pullEpcResourcesByTxnId", error);
    logException("Err pullingEpcResourcesByTxnId [utils helper]", {
      error: error || {},
      message: "Err pullingEpcResourcesByTxnId [utils helper]",
    });
    return error;
  }
}

export function prepareResourceRequest(docs: any) {
  if (!docs.length) return [];
  const array =
    docs
      .filter((it: any) => it.DOCUMENT.SentOrReceived === "Received")
      .map((_: any, i: number) => {
        var { FileName, FileType } = _.DOCUMENT;
        return {
          id: ++i,
          name: FileName,
          mimeType: mimeTypeMapper(FileType),
        };
      }) || [];
  return array;
}

export function determineProductType(
  productType: string,
  isPurchaseOrder: boolean,
  isSigningOnlyOrder: boolean
): string {
  // If isSigningOnlyOrder is true, return SigningOnlyOrder immediately
  if (isSigningOnlyOrder) {
    return "signingonly";
  }

  switch (productType) {
    case EncompassConstants.CashOutRefinance:
    case EncompassConstants.NoCashOutRefinance:
    case EncompassConstants.ConstructionToPermanent:
      return "Refinance";

    case EncompassConstants.Construction:
      return isPurchaseOrder ? "Purchase" : "Refinance";

    default:
      return productType;
  }
}

// SRC
export function getRateCalculatorURL(
  transactionType: string,
  state: string,
  county: string,
  city: string,
  companyId: string,
  loanNumber: string,
  isCTS: boolean,
  pricingGUID: string,
  loanamount: number,
  salesprice: number
) {
  let url = "https://staging.stewartratecalculator.com";
  let transactionTypeParam = getTransactionTypeParam(transactionType);

  url += "/?userrole=1&quotetype=3";

  if (state) url += "&state=" + state;
  if (county) url += "&county=" + county;
  if (city) url += "&city=" + city;
  if (transactionTypeParam) url += "&transactionType=" + transactionTypeParam;
  url += "&refid=" + getPricingQuoteReferenceNumber(companyId, loanNumber);
  if (isCTS) url += "&officeid=" + pricingGUID;
  if (salesprice) url += "&salesprice=" + salesprice.toFixed(2);
  if (loanamount) url += "&loanamount=" + loanamount.toFixed(2);
  return url;
}

export function getTransactionTypeParam(transactionType: string) {
  let value = "";

  switch (transactionType) {
    case EncompassConstants.Purchase:
      value = "sale";
      break;
    case EncompassConstants.CashOutRefinance:
    case EncompassConstants.NoCashOutRefinance:
    case EncompassConstants.ConstructionToPermanent:
      value = "refi";
      break;
    case EncompassConstants.Construction:
      value =
        transactionType === EncompassConstants.Construction ? "sale" : "refi";
      break;
  }
  return value;
}

export function getPricingQuoteReferenceNumber(
  companyId: string,
  loanNumber: string
) {
  if (!companyId || !loanNumber) return "";
  return `ENC-${loanNumber}-${companyId}`;
}

export function isValidFileName(fileName: string) {
  if (!fileName || !fileName.length) return;

  const invalidFileNameChars = /[\/\\\:\*\?"<>\|]/g;
  return !invalidFileNameChars.test(fileName);
}

export async function handleCloseTransaction() {
  try {
    const Path = getUrl(ApiPathConsts.revocation);
    await axiosEcpInstance.post(Path);
    const transactionObject = await host.getObject("transaction");
    transactionObject.close();
  } catch (error: unknown) {
    console.error("ERR handleCloseTransaction", error);
    logException("Err handleCloseTransaction [utils helper]", {
      error: error || {},
      message: "Err handleCloseTransaction [utils helper]",
    });
  }
}

export async function prepareDocumentsPayload(
  documentList: any[],
  txnId: string
): Promise<any[]> {
  let documents: any[] = [];
  if (documentList?.length) {
    // documents = await Promise.all(
    //   documentList
    //     // .filter((item: any) => item?.DOCUMENT?.Status?.includes("Pending"))
    //     .map(async (docItem: any) => {
    //       const { SentOrReceived, Status, DocUrl, DocUrlSign, ...rest } =
    //         docItem.DOCUMENT;
    //       const docContent = await getbase64ContentFromUrl(DocUrl, DocUrlSign);
    //       return { ...rest, DocumentContent: docContent };
    //     })
    // );
    try {
      const { data } = (await pullEpcResourcesByTxnId(txnId)) || [];
      documents = documentList.map((_: any) => {
        const { SentOrReceived, Status, DocUrl, DocUrlSign, ...rest } =
          _.DOCUMENT;
        const { content } = data.find(
          ($: any) => $.id === rest.DocumentIdentifier
        );
        return { ...rest, DocumentContent: content };
      });
    } catch (error) {
      logException("Error prepareDocumentsPayload [utils helper]", {
        error: error || {},
        message: "Error prepareDocumentsPayload [utils helper]",
      });
    }
  }
  return documents;
}

export function deepCompare(x: unknown, y: unknown): boolean {
  if (x === y) {
    return true;
  } else if (isNonNullObject(x) && isNonNullObject(y)) {
    const xProps = Object.keys(x);
    const yProps = Object.keys(y);

    if (xProps.length !== yProps.length) return false;

    for (const prop of xProps) {
      if (prop in y) {
        if (!deepCompare(x[prop], y[prop])) return false;
      } else return false;
    }

    return true;
  } else return false;
}

function isNonNullObject(value: unknown): value is Record<string, unknown> {
  return typeof value === "object" && value !== null;
}
