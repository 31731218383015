// operationId.js
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface OperationId {
  operationId: "";
}

const initialState: OperationId = {
  operationId: "",
};

const operationId = createSlice({
  name: "operationId",
  initialState,
  reducers: {
    AddOperationIdInStore: (state, action: PayloadAction<any>) => {
      state.operationId = action.payload || "";
    },
  },
});

export const { AddOperationIdInStore } = operationId.actions;
export default operationId.reducer;
