import React from "react";
import { Button, Grid as MuiGrid, Box } from "@mui/material";
import {
  useController,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";

import EPCInput from "componnets/atoms/EPCInput";
import AutocompleteComponent from "componnets/atoms/srcAutocomplete";
import MuiTextField from "componnets/atoms/textField";
import AddButton from "componnets/atoms/button";
import Radiogroup from "componnets/atoms/radiogroup";
import Typography from "componnets/atoms/typography";
import CurrencyTextField from "componnets/atoms/CurrencytextField";

import Grid from "componnets/molecules/grid";

import { FormComponent } from "componnets/organisms/src/TitleInsurancepremium/PremiumDetails";

import { withFields } from "hoc/withField";
import { formatCurrency } from "utils/srcutils";

export const componentMapping: { [key: string]: React.FC<any> } = {
  DropDown: withFields(AutocompleteComponent) as React.FC<any>,
  AddButton: withFields(AddButton) as React.FC<any>,
  RadioGroup: withFields(Radiogroup) as React.FC<any>,
  Button: withFields(Button) as React.FC<any>,
  TextField: withFields(MuiTextField) as React.FC<any>,
  Input: withFields(EPCInput) as React.FC<any>,
  Typography: withFields(Typography) as React.FC<any>,
  Grid: withFields(Grid) as React.FC<any>,
  CurrencyTextField: withFields(CurrencyTextField) as React.FC<any>, // Add custom component
};

interface RendertitleInsurancepremiumProps {
  titleInsurancepremium: FormComponent[]; // Correct the type here
}

interface RenderEndorsementProps {
  endorsements: FormComponent[]; // Correct the type here
}
interface RenderTitleServiceFeeProps {
  titleServiceFee: FormComponent[]; // Correct the type here
}
interface RecordingFeeProps {
  recordingFee: FormComponent[]; // Correct the type here
}
interface RenderComponent {
  component: FormComponent;
  index?: number;
}

export const RenderFormComponent: React.FC<RenderComponent> = ({
  component,
  index,
}) => {
  if (component?.fieldType === "Subfields" && component.subfields) {
    return (
      <>
        <MuiGrid
          container
          spacing={1}
          alignItems={"center"}
          pl={2}
          columnGap={4}
          pb={4}
        >
          {component.subfields.map((subfield, index) => (
            <MuiGrid item key={index}>
              <RenderFormComponent component={subfield} index={index} />
            </MuiGrid>
          ))}
        </MuiGrid>
      </>
    );
  }
  const ComponentToRender = componentMapping[component?.fieldType];

  if (!ComponentToRender) {
    console.error(`Unsupported field type: ${component?.fieldType}`);

    return null;
  }
  // if (component.hidden) return null;
  const { fieldType, caption, gridSize, name = "", ...restProps } = component;

  const { control } = useForm<any>();
  const { setValue, getValues } = useFormContext();

  const isGrid = gridSize ? true : false;
  const size = ["TextField", "CurrencyTextField"].includes(component?.fieldType)
    ? "small"
    : "medium";

  const { field, fieldState } = useController({
    name,
    control,
    rules: { required: true },
  });

  const handleBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (component.fieldType === "CurrencyTextField") {
      const numericValue = event.target.value.replace(/[^0-9.-]+/g, "");
      let formattedValue = numericValue;
      if (numericValue) {
        formattedValue = formatCurrency(numericValue);
      }

      setValue(name, formattedValue);
      field.onChange(formattedValue);
    }
    setValue(name, event.target.value);
    field.onChange(event.target.value);
  };
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue(name, event.target.value);
    field.onChange(event.target.value);
  };

  const propsToPass = {
    ...restProps,
    isGrid,
    size,
    sx: { display: "flex" },
    fieldType: fieldType,
    ...field,
    onblur: handleBlur,
    onChange: handleChange,
    ...fieldState,
    // ...formState
  };

  return (
    // <Box key={index} sx={{ flex: 1 }}>
    <ComponentToRender {...propsToPass} />
    // </Box>
  );
};
export const RendertitleInsurancepremium: React.FC<
  RendertitleInsurancepremiumProps
> = ({ titleInsurancepremium }) => {
  return (
    <>
      {" "}
      {titleInsurancepremium.map((component, index) => (
        <RenderFormComponent key={index} component={component} index={index} />
      ))}
    </>
  );
};

export const RenderEndorsement: React.FC<RenderEndorsementProps> = ({
  endorsements,
}) => {
  return (
    <>
      {endorsements.map((component, index) => (
        <RenderFormComponent key={index} component={component} index={index} />
      ))}
    </>
  );
};

export const RenderTitleServiceFee: React.FC<RenderTitleServiceFeeProps> = ({
  titleServiceFee,
}) => {
  return (
    <>
      {titleServiceFee.map((component, index) => (
        <RenderFormComponent key={index} component={component} index={index} />
      ))}
    </>
  );
};

export const RenderRecordingFee: React.FC<RecordingFeeProps> = ({
  recordingFee,
}) => {
  return (
    <>
      {recordingFee.map((component, index) => (
        <RenderFormComponent key={index} component={component} index={index} />
      ))}
    </>
  );
};
