export const apiConst: Record<string, Record<string, string>> = {
  development: {
    createToken: "/login",
    getTransorigin: "/origins/{id}",
    getContracts: "/Contracts",
    placeOrder: "/titleOrderRequest",
    getOffices: "/getOffices",
    orderStatus: "/orderStatus",
    getTransaction: "/getTransaction/{transaction_id}",
    pullDocsFromEpc: "/pullDocsFromEpc/{transaction_id}",
    getLenderSettings: "/lenderSettings",
    sendNotes: "/sendNotes",
    updateTransaction: "/updateTransaction/{transaction_id}",
    getStatusList: "/statusList",
    cancelOrder: "/cancelOrder",
    revocation: "/revocation",
    getSystemAttributes: "/getSystemAttributes",
    getCounty: "/getCounty/{state}",
    getProviders: "getProvidersList",
    getPolicyInsuredTypes: "getPolicyInsuredTypes",
    stateSettings: "/papi/statesettings/{state}",
  },
};
