import React from "react";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Button, CircularProgress } from "@mui/material";
import AppThemeProvider from "themes/AppThemeProvider";
import store from "redux/store";
import { Provider } from "react-redux";
import { SRC } from "pages/SRC";
import SetupLender from "pages/setupLender";
import StewartRC from "componnets/StewartSRC/RateCaluculator";

const ErrorFallback = () => {
  return (
    <div
      className="text-red-500 w-screen h-screen flex flex-col justify-center items-center"
      role="alert"
    >
      <h2 className="text-lg font-semibold">Ooops, something went wrong :( </h2>
      <Button
        className="mt-4"
        onClick={() => window.location.assign(window.location.origin)}
      >
        Refresh
      </Button>
    </div>
  );
};

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <Suspense
      fallback={
        <div className="flex items-center justify-center w-screen h-screen">
          <CircularProgress size="xl" />
        </div>
      }
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Provider store={store}>
          <AppThemeProvider>
            <Router>
              <Routes>
                <Route path="/" element={children} />

                <Route path="/src" element={<StewartRC />} />

                <Route path="/request" element={<SetupLender />} />

                <Route path="*" element={<div>Page not found!</div>} />
              </Routes>
            </Router>
          </AppThemeProvider>
        </Provider>
      </ErrorBoundary>
    </Suspense>
  );
};
