// operationId.js
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FormSchema, WithoutSplitSchema } from "componnets/organisms/src/TitleInsurancepremium/utils";

interface transactionDetailsTypes {
    data:  FormSchema|WithoutSplitSchema|[] 
}

const initialState: transactionDetailsTypes = {
    data:[]
};

const insurancePremimSlice = createSlice({
  name: "titlePremium",
  initialState,
  reducers: {

      setData: (state, action: PayloadAction<FormSchema|WithoutSplitSchema|[] >) => {
        return {
          ...state,
          data:action.payload
        };
      },
  },
});

export const {setData} = insurancePremimSlice.actions;
export default insurancePremimSlice.reducer;
