import CustomAccordion from 'componnets/molecules/Accordion';
import type { FC } from 'react';
import Endorsements from './Endorsements';

interface EndorsementProps {}

const Endorsement: FC<EndorsementProps> = () => {
    return (
        
        <CustomAccordion
        summary='Endorsements/ Other Products'
        >
        <Endorsements/>
        </CustomAccordion>
    );
}

export default Endorsement;
