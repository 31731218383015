import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface BusinessLocation {
  ID: string;
  ProviderCode: string;
  Name: string;
  Address: string;
  City: string;
  StateCode: string;
  ZipCode: string;
  Phone: string;
  ProximityFromProperty: string;
  IsProviderWithFee: string;
}

interface TransactionType {
  Code: string;
  Description: string;
  ReissueQuestion: string;
  IsReverse: string;
  IsDisplaySplits: string;
}

interface EndorsementType {
  EndorsementFeeItemCode: string;
  Description: string;
  LegalDescription: string;
  Type: string;
  IsCommon: string;
  IsPreselected: string;
  QuestionList: string;
}

interface SRCState {
  providers: BusinessLocation[];
  selectedProviders: Record<string, string>;
  selectedItems: number[];

  selected: number;
  slectedTransaction: string;
  selectedTransObject: TransactionType | {};
}

const initialState: SRCState = {
  providers: [],
  selectedProviders: {},
  selectedItems: [],

  selected: 0,
  slectedTransaction: "",
  selectedTransObject: {},
};

const src = createSlice({
  name: "SRC",
  initialState,
  reducers: {
    setProviders: (state, action: PayloadAction<BusinessLocation[]>) => {
      state.providers = action.payload;
    },
    setSelectedProviders: (
      state,
      action: PayloadAction<Record<string, string>>
    ) => {
      state.selectedProviders = action.payload;
    },
    setSelectedItems: (state, action: PayloadAction<number[]>) => {
      state.selectedItems = action.payload;
    },

    setSelected: (state, action: PayloadAction<number>) => {
      state.selected = action.payload;
    },
    setSelctedTransaction: (
      state,
      action: PayloadAction<{
        transacTypeParam: string;
        selectedTransac: TransactionType;
      }>
    ) => {
      const { transacTypeParam, selectedTransac } = action.payload;
      state.slectedTransaction = transacTypeParam;
      state.selectedTransObject = selectedTransac;
    },
  },
});

export const {
  setProviders,
  setSelectedProviders,
  setSelectedItems,
  setSelected,
  setSelctedTransaction,
} = src.actions;

export default src.reducer;
