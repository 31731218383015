import { StewartConstants } from "constants/strings";
import host from "@elliemae/em-ssf-guest";

import { EncompassPayload, Document } from "interfaces/loanPayload";
import { EncompassData, OptionsData } from "interfaces/rootState";

import {
  createResourceOnEpcForRwFiles,
  formatServerDateToLocal,
  getValueOrDefault,
  mimeTypeMapper,
} from "./helpers";
import { getCanonicalSuffixName } from "./getCanonicalSuffix";
import store from "redux/store";
import { setPropertyType } from "redux/features/loanSlice";
import { logException } from "componnets/atoms/Logger";

export function createEncompassPayload(
  params: Record<string, any>,
  documentList?: Document[]
): EncompassPayload | any {
  const eventType = getValueOrDefault(params, "eventType", "PlaceOrder");
  const isPlaceOrder = eventType === "PlaceOrder";
  const isNote = eventType === "Note";

  const isSelectedOfficeID =
    getValueOrDefault(params, "SelectedOfficeId", "") !== "";

  const payoffsToSend = params?.payoffSelectedRows?.length
    ? (params?.PayoffInformation?.PayoffLenders?.PayoffLender || []).filter(
        (_: any, i: number) => params.payoffSelectedRows.includes(i)
      ) || []
    : [];

  const payoffNotesArr =
    payoffsToSend.map((_: any) => ({
      subject: "Payoff Information",
      Message:
        Object.entries(_)
          .map(([key, value]) => `${key}: ${value}`)
          .join("\n") || "",
    })) || [];

  const payload: EncompassPayload = {
    Encompass: {
      Header: {
        ClientID: getValueOrDefault(params, "clientID", 0),
        CompanyID: getValueOrDefault(params, "companyID", "STW987654321"),
        ProviderID: getValueOrDefault(params, "providerID", ""), //not found,
        ...(isSelectedOfficeID && {
          OfficeID: getValueOrDefault(params, "SelectedOfficeId", ""),
        }),
        ...(!isPlaceOrder && {
          ContextID: getValueOrDefault(params, "contextID", ""),
        }),
        EPCTransactionID: getValueOrDefault(params, "TransactionId", ""),
      },
      Order: {
        Event: {
          Type: eventType,
        },
        ...(isPlaceOrder && {
          IsCTS: getValueOrDefault(params, "IsCTS", true),
        }),
        ...((isNote || isPlaceOrder) && {
          Notes: {
            Note: [
              ...getValueOrDefault(params, "notesArr", []),
              ...payoffNotesArr,
              ...prepareTrusteeNote(params?.closingDocument),
            ],
          },
        }),
        ...(isPlaceOrder && {
          Borrower: {
            FirstName: getValueOrDefault(params, "borrowerFirstName", ""),
            MiddleName: getValueOrDefault(params, "borrowerMiddleName", ""),
            LastName: getValueOrDefault(params, "borrowerLastName", ""),
            Suffix: getCanonicalSuffixName(
              getValueOrDefault(params, "borrowerSuffix", "")
            ),
            PhoneNumber: getValueOrDefault(params, "borrowerPhoneNumber", ""),
            WorkNumber: getValueOrDefault(params, "borrowerWorkNumber", ""),
            CellNumber: getValueOrDefault(params, "borrowerCellNumber", ""),
            EmailAddress: getValueOrDefault(params, "borrowerEmailAddress", ""),
            MaritalStatus: getValueOrDefault(
              params,
              "borrowerMaritalStatus",
              ""
            ),
            Address: getValueOrDefault(params, "borrowerAddress", ""),
            City: getValueOrDefault(params, "borrowerCity", ""),
            State: getValueOrDefault(params, "borrowerState", ""),
            ZipCode: getValueOrDefault(params, "borrowerZipCode", ""),
            TaxPayerIdentification: getValueOrDefault(
              params,
              "borrowerTaxPayerIdentification",
              ""
            ),
          },
        }),
        ...(isPlaceOrder && {
          "Co-Borrower": {
            FirstName: getValueOrDefault(params, "coBorrowerFirstName", ""),
            MiddleName: getValueOrDefault(params, "coBorrowerMiddleName", ""),
            LastName: getValueOrDefault(params, "coBorrowerLastName", ""),
            Suffix: getCanonicalSuffixName(
              getValueOrDefault(params, "coBorrowerSuffix", "")
            ),
            PhoneNumber: getValueOrDefault(params, "coBorrowerPhoneNumber", ""),
            WorkNumber: getValueOrDefault(params, "coBorrowerWorkNumber", ""),
            CellNumber: getValueOrDefault(params, "coBorrowerCellNumber", ""),
            EmailAddress: getValueOrDefault(
              params,
              "coBorrowerEmailAddress",
              ""
            ),
            MaritalStatus: getValueOrDefault(
              params,
              "coBorrowerMaritalStatus",
              ""
            ),
            Address: getValueOrDefault(params, "coBorrowerAddress", ""),
            City: getValueOrDefault(params, "coBorrowerCity", ""),
            State: getValueOrDefault(params, "coBorrowerState", ""),
            ZipCode: getValueOrDefault(params, "coBorrowerZipCode", ""),
            TaxPayerIdentification: getValueOrDefault(
              params,
              "coBorrowerTaxPayerIdentification",
              ""
            ),
          },
        }),
        ...(isPlaceOrder &&
          params?.closingDocument?.hasOwnProperty("borrowerUnparsedName1") && {
            AdditionalBorrowers: {
              Borrower: prepareTrusteeBody(params?.closingDocument),
            },
          }),
        ...(isPlaceOrder && {
          Lender: {
            ClientName: getValueOrDefault(params, "lenderClientName", ""),
            FirstName: getValueOrDefault(params, "userName", ""),
            PhoneNumber: getValueOrDefault(params, "lenderPhoneNumber", ""),
            FaxNumber: getValueOrDefault(params, "lenderFaxNumber", ""),
            EmailAddress: getValueOrDefault(params, "lenderEmailAddress", ""),
          },
        }),
        ...(isPlaceOrder && {
          Loan: {
            LoanPurpose: getValueOrDefault(params, "loanPurpose", ""),
            Product: getValueOrDefault(params, "loanProduct", "TITLE"),
            ClientFileNumber: getValueOrDefault(params, "clientFileNumber", ""), // not found
            SalePrice: getValueOrDefault(params, "purchasePriceAmount", 0),
            LoanAmount: getValueOrDefault(params, "baseLoanAmount", 0),
            LoanNumber: getValueOrDefault(params, "loanNumber", ""),
            HelocInsured: getValueOrDefault(params, "HelocInsured", false),
            ApplicationDate: getValueOrDefault(params, "originationDate", ""),
            MortgageType: getValueOrDefault(params, "MortgageType", ""),
            EstimatedSettlementDate: getValueOrDefault(
              params,
              "loanScheduledClosingDate",
              ""
            ),
            ClosingDate: getValueOrDefault(params, "closingDate", ""),
          },
        }),
        ...(isPlaceOrder && {
          MailingAddress: {
            Address: getValueOrDefault(params, "mailingAddress", ""),
            City: getValueOrDefault(params, "mailingCity", ""),
            State: getValueOrDefault(params, "mailingState", ""),
            ZipCode: getValueOrDefault(params, "mailingZipCode", ""),
          },
        }),
        ...(isPlaceOrder && {
          Property: {
            Street: getValueOrDefault(params, "propertyStreet", ""),
            City: getValueOrDefault(params, "propertyCity", ""),
            State: getValueOrDefault(params, "propertyState", ""),
            County: getValueOrDefault(params, "propertyCounty", ""),
            ZipCode: getValueOrDefault(params, "propertyZipCode", 0),
            LegalDescription: getValueOrDefault(
              params,
              "propertyLegalDescription",
              ""
            ),
            ParcelID: getValueOrDefault(params, "ParcelID", ""),
          },
        }),

        ...(documentList?.length && {
          Documents: { DOCUMENT: documentList },
        }),
        ...(params?.payoffSelectedRows?.length &&
          !params.isOrderPlaced && {
            PayoffInformation: {
              PayoffLenders: {
                PayoffLender: payoffsToSend,
              },
            },
          }),
      },
    },
  };

  return payload;
}

function extractFncOrderNumber(subject: string) {
  if (
    subject
      .toUpperCase()
      .startsWith(StewartConstants.FNC_ORDER_NOTE_SUBJECT.toUpperCase())
  ) {
    const parts = subject.split(":");
    return parts.length > 0 ? parts[parts.length - 1].trim() : "";
  }
  return "";
}

export function toArray<T>(item: T | T[]): T[] {
  if (Array.isArray(item)) {
    return item;
  } else if (item) {
    return [item];
  } else {
    return [];
  }
}

function createDocumentObj(doc: any, docIndex: number, optsData: any): any {
  const [fileName = "", fileExt = ""] = doc.FileName
    ? doc.FileName.split(".")
    : [];

  return {
    DOCUMENT: {
      SentOrReceived: "Received",
      Status: "Success",
      DocumentContent: "",
      DocumentDateTime: formatServerDateToLocal(doc.CreateDate),
      DocumentDescription: "",
      DocumentIdentifier: doc.id,
      DocumentName: fileName,
      DocumentType: "",
      EncodeType: "Base64",
      FileName: doc.FileName,
      FileType: `.${fileExt}`,
      // DocUrl: doc.DocUrl,
      // DocUrlSign: doc.DocUrlSign,
    },
  };
}

// interface EncompassResponse {
//   TitleSearchData?: SearchData[];
// }

// Assuming EncompassFieldIDs is an already defined enumeration or object
const EncompassFieldIDs = {
  PropertyLegalDescriptionInfo: "PropertyLegalDescriptionInfo",
  ParcelID: "ParcelID",
  BorrowerFinalVesting: "BorrowerFinalVesting",
};

// Define the structure of the updateData object for type safety

// Utility function to process OrderStatus and return updateData
// export function processOrderStatus(Searchdata: SearchData): UpdateDataType {
//   const updateData: UpdateDataType = {
//     closingDocument: {},
//     property: {},
//   };

//   if (Searchdata && Object.keys(Searchdata).length) {
//     const { Vesting = "", ParcelID = "", Legal = "" } = Searchdata;

//     if (Vesting && Vesting.length) {
//       updateData.closingDocument.finalVestingDescription = Vesting;
//     }

//     if (ParcelID && ParcelID.length) {
//       updateData.property.assessorsParcelIdentifier = ParcelID;
//     }

//     if (Legal && Legal.length) {
//       updateData.closingDocument.textDescription = Legal;
//     }
//   }

//   return updateData
// }

export function updateSearchData(TitleSearchData: any): Record<string, string> {
  const Result: Record<string, string> = {};

  if (TitleSearchData?.SearchData) {
    if (TitleSearchData.SearchData.Legal) {
      Result.Legal = TitleSearchData.SearchData.Legal;
    }

    if (TitleSearchData?.SearchData.ParcelID) {
      Result.ParcelID = TitleSearchData.SearchData.ParcelID;
    }

    if (TitleSearchData?.SearchData.Vesting) {
      Result.Vesting = TitleSearchData.SearchData.Vesting;
    }
  }
  return Result;
}

export async function processEncompassData(
  responseData: EncompassData,
  optsData: any,
  txnID: string
): Promise<OptionsData> {
  const { Encompass } = responseData;
  const { Header, StatusList, Notes, Documents, TitleSearchData } =
    Encompass || {};
  const Searchdata = updateSearchData(TitleSearchData);
  if (Object.keys(Searchdata).length) {
    store.dispatch(setPropertyType(Searchdata));
  }
  const clientFileNumber = Header?.ClientFileNumber ?? "";
  const updatedOptionsData: OptionsData = clientFileNumber
    ? { clientFileNumber }
    : {};
  const result =
    Array.isArray(optsData.status) &&
    optsData.status.some(
      (cv: Record<string, string>) => cv.Name === "Order Confirmed"
    );
  const isOrderCanceled =
    Array.isArray(optsData.status) &&
    optsData.status.some(
      (cv: Record<string, string>) => cv.Name === "Order Cancelled"
    );

  // updatedOptionsData.Searchdata = Searchdata;
  updatedOptionsData.isOrderCanceled = isOrderCanceled;

  updatedOptionsData.isOrderConfirmed = result;
  let OrderNumber: string = "";
  if (StatusList?.Status) {
    const statuses = toArray(StatusList.Status);
    const existingStatusNames = optsData.status
      ? optsData.status.map((cv: any) => cv.Name)
      : [];

    const newStatuses = statuses.filter(
      (status) => !existingStatusNames.includes(status.Name)
    );

    if (newStatuses.length > 0) {
      updatedOptionsData.Status = newStatuses.map((status) => ({
        ...status,
      }));
    }

    if (!updatedOptionsData.Status) {
      updatedOptionsData.Status = [];
    }

    const isOrderCanceled =
      Array.isArray(updatedOptionsData.Status) &&
      updatedOptionsData.Status.some(
        (cv: Record<string, string>) => cv.Name === "Order Cancelled"
      );
    const isOrderConfirmed =
      Array.isArray(updatedOptionsData.Status) &&
      updatedOptionsData.Status.some(
        (cv: Record<string, string>) => cv.Name === "Order Confirmed"
      );

    updatedOptionsData.isOrderConfirmed = isOrderConfirmed;

    updatedOptionsData.isOrderCanceled = isOrderCanceled;
  }
  if (Notes?.Note) {
    const notesArray = toArray(Notes.Note);
    updatedOptionsData.Note = notesArray.map((note) => {
      OrderNumber = extractFncOrderNumber(note.Subject);
      return { ...note, Status: "Received" };
    });
  }
  updatedOptionsData.fncOrderNumber = OrderNumber;
  if (Documents?.Document) {
    const documentsArray = toArray(Documents.Document);
    const filesFromRW = documentsArray.map((file: any) => ({
      content: file.Content,
      filename: file.FileName,
      mimeType: mimeTypeMapper(`.${file.FileName.split(".")?.[1]}`),
    }));
    console.log("Formatted files from RW", filesFromRW);
    let epcFileData: any[] = [];
    try {
      epcFileData =
        (await createResourceOnEpcForRwFiles(
          host,
          filesFromRW,
          txnID,
          [],
          true
        )) ?? [];
      const transactionObject = await host.getObject("transaction");
      const txnRes = await transactionObject.update({
        request: {
          options: optsData,
          // @ts-ignore
          resources: epcFileData.map(({ name, id, mimeType, type }) => ({
            name,
            id,
            mimeType,
            type,
          })),
        },
      });
      console.log("txn updated on rw file received", txnRes);
    } catch (error) {
      logException("Error txn update processEncompassData [placeOrder.ts]", {
        error: error || {},
        message: "Error txn update processEncompassData [placeOrder.ts]",
      });
    }

    if (Array.isArray(epcFileData) && epcFileData.length) {
      documentsArray.forEach((_: any, idx: number) => {
        // @ts-ignore
        _.DocUrl = epcFileData[idx].location;
        // @ts-ignore
        _.DocUrlSign = epcFileData[idx].authorization;
        // @ts-ignore
        _.id = epcFileData[idx].id;
        _.type = epcFileData[idx].type;
      });
      updatedOptionsData.rwDocs = documentsArray.map((doc) => ({
        content: "",
        name: doc.FileName,
        mimeType: mimeTypeMapper(`.${doc.FileName.split(".")?.[1]}`),
        fileurl: doc.DocUrl,
        fileurlsign: doc.DocUrlSign,
        id: doc.id,
        type: doc.type,
      }));
      updatedOptionsData.Documents = documentsArray
        .map((doc, index) => createDocumentObj(doc, index, optsData))
        .concat(optsData.Documents || []);
    }
  }

  return updatedOptionsData;
}

export function createDocumentPayload(
  UserName: string,
  fileNum: string,
  ContextID: string,
  docsArr: any[]
) {
  return {
    Header: {
      ActionName: "Document",
      ContextID,
      Receiver: "STW987654321",
      Sender: "Encompass",
      UserName,
    },
    Message: {
      Orders: {
        Order: {
          OrderNumber: fileNum,
          Documents: { DOCUMENT: docsArr },
        },
      },
    },
  };
}

// Utility to prepare trustee info for placeorder body
function prepareTrusteeBody(trusteeInfo: any = {}) {
  const array = [];
  if (trusteeInfo.hasOwnProperty("borrowerUnparsedName1")) {
    array.push({
      FullName: trusteeInfo.borrowerUnparsedName1 || "",
      TaxPayerIdentification:
        +trusteeInfo.borrowerTaxIdentificationNumberIdentifier1 || "",
      PartyTypeClassification: trusteeInfo.borrowerOrganizationType1 || "",
      OrganizationState:
        trusteeInfo.borrowerOrganizedUnderTheLawsOfJurisdictionName1 || "",
      OrganizationType: trusteeInfo.borrowerOrganizationType1 || "",
      FinalVestingToRead: trusteeInfo.finalVestingDescription || "",
    });
  }
  if (trusteeInfo.hasOwnProperty("borrowerUnparsedName2")) {
    array.push({
      FullName: trusteeInfo.borrowerUnparsedName2 || "",
      TaxPayerIdentification:
        +trusteeInfo.borrowerTaxIdentificationNumberIdentifier2 || "",
      PartyTypeClassification: trusteeInfo.borrowerOrganizationType2 || "",
      OrganizationState:
        trusteeInfo.borrowerOrganizedUnderTheLawsOfJurisdictionName2 || "",
      OrganizationType: trusteeInfo.borrowerOrganizationType2 || "",
      FinalVestingToRead: trusteeInfo.finalVestingDescription || "",
    });
  }

  return array;
}

// Utility to prepare trustee info as "Note" for placeorder body
function prepareTrusteeNote(trusteeInfo: any = {}) {
  const array = [];
  if (trusteeInfo.hasOwnProperty("borrowerUnparsedName1")) {
    array.push({
      subject: "Corporation Details",
      Message: `Name: ${
        trusteeInfo.borrowerUnparsedName1 || ""
      }\nOrganization State: ${
        trusteeInfo.borrowerOrganizedUnderTheLawsOfJurisdictionName1 || ""
      }\nOrganization Type: ${
        trusteeInfo.borrowerOrganizationType1 || ""
      }\nFinal Vesting to Read: ${trusteeInfo.finalVestingDescription || ""}`,
    });
  }
  if (trusteeInfo.hasOwnProperty("borrowerUnparsedName2")) {
    array.push({
      subject: "Corporation Details",
      Message: `Name: ${
        trusteeInfo.borrowerUnparsedName2 || ""
      }\nOrganization State: ${
        trusteeInfo.borrowerOrganizedUnderTheLawsOfJurisdictionName2 || ""
      }\nOrganization Type: ${
        trusteeInfo.borrowerOrganizationType2 || ""
      }\nFinal Vesting to Read: ${trusteeInfo.finalVestingDescription || ""}`,
    });
  }

  return array;
}
