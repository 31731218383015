export function formatCurrency(
  number: number | string,
  isBlur?: boolean
): string {
  // Check if the number is already formatted by looking for a comma or currency symbol
  if (
    typeof number === "string" &&
    (/[\d,]+\.\d{2}/.test(number) || number.includes("$"))
  ) {
    return number; // Return as-is if already formatted
  }

  // Convert to number if it's a string representation of a number
  const num = typeof number === "string" ? parseFloat(number) : number;

  // Default formatting options
  const defaultOptions: Intl.NumberFormatOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  // Create a formatter using Intl.NumberFormat
  const formatter = new Intl.NumberFormat(undefined, defaultOptions);

  // Format the number
  let formattedNumber = formatter.format(num);

  // Append dollar sign if not already present

  if (!formattedNumber.includes("$")) {
    formattedNumber = `$${formattedNumber}`;
  }

  return formattedNumber;
}
