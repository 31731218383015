import CustomAccordion from 'componnets/molecules/Accordion';
import type { FC } from 'react';
import TitleServiceFeeDetails from './TitleServiceFeeDetails';
import {useState} from 'react'
import { useSelector } from 'react-redux';
import { RootState } from 'interfaces/rootState';
import EPCModal from 'componnets/atoms/EpcModal';
import MultiLineTypography from 'componnets/atoms/multiLineText';

interface TitleServicefeeProps {}

const TitleServicefee: FC<TitleServicefeeProps> = () => {
    const transaction=useSelector(
        (state: RootState) => state.src.slectedTransaction
      );
   
    const [selectOffice,setSelectoffice]=useState(false)
    const countyCode = useSelector(
        (state: RootState) => state.countyCode.
        countyCode
      );
    const OnClickHandler=()=>{
        if (!state || !countyCode || !city || !transaction) {
            setSnackbarOpen(true)
            setSnackbarMessage("Please ensure all required fields (state, county code, city,transaction Type) are filled.");
            return;
          }
        setSelectoffice((state)=>!state)
    }
    const property = useSelector((state: RootState) => state.loan.data.loan?.property) ?? {};

    const { city, state } = property ?? {};

  
      const [snackbarOpen, setSnackbarOpen] = useState(false);
      const [snackbarMessage, setSnackbarMessage] = useState("");

      const handleSnackbarClose = () => {
        setSnackbarOpen(false);
      };
    return (
        <>
          <CustomAccordion
        summary='Title Service Fees'
        ButtonText='Select Settlement Office'
        onClick={OnClickHandler}
        >
        <TitleServiceFeeDetails selectOffice={selectOffice} setSelectoffice={setSelectoffice}/>
        </CustomAccordion>
          <EPCModal
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        showOkButton
      >
        <MultiLineTypography text={snackbarMessage} />
      </EPCModal>
        </>
      
    );
}

export default TitleServicefee;
