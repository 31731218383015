
import { RenderRecordingFee } from 'componnets/services/RenderForms';
import type { FC } from 'react';

interface RecordingFeeDetailsProps {}

const formSchema: any[] = [
    {
      fieldType: 'Grid',
      headers: [
        { fieldType: 'Typography', label: '', gridSize: 2, variant: 'header' }, // Empty column for leftmost label
        { fieldType: 'Typography', label: 'Buyer Amount', gridSize: 3, variant: 'header' },
        { fieldType: 'Typography', label: 'Seller Amount', gridSize: 3, variant: 'header' },
        { fieldType: 'Typography', label: 'Total', gridSize: 1, variant: 'header' },
      ],
      rows: [
        [
          { fieldType: 'Typography', label: 'Mortgage Conservation Fund Fee', gridSize: 2,variant: 'rowText'  },
          { fieldType: 'CurrencyTextField', name: 'mortgageConservationBuyer', defaultValue: '5.00', gridSize: 3  },
          { fieldType: 'CurrencyTextField', name: 'mortgageConservationSeller', defaultValue: '0.00', gridSize: 3 },
          { fieldType: 'Typography', label: '$5.00', gridSize: 1, variant: 'rowText' }
        ],
        [
          { fieldType: 'Typography', label: 'Deed Conservation Fund Fee', gridSize: 2,variant: 'rowText' },
          { fieldType: 'CurrencyTextField', name: 'deedConservationBuyer', defaultValue: '0.00', gridSize: 3 },
          { fieldType: 'CurrencyTextField', name: 'deedConservationSeller', defaultValue: '3.00', gridSize: 3 ,},
          { fieldType: 'Typography', label: '$5.00', gridSize: 1,variant: 'rowText' }
        ],
        [
          { fieldType: 'Typography', label: 'Mortgage Tax - State', gridSize: 2,variant: 'rowText' },
          { fieldType: 'CurrencyTextField', name: 'mortgageTaxBuyer', defaultValue: '414.00', gridSize: 3 },
          { fieldType: 'CurrencyTextField', name: 'mortgageTaxSeller', defaultValue: '0.00', gridSize: 3 },
          { fieldType: 'Typography', label: '$414.00', gridSize: 1, variant: 'rowText' }
        ],
        [
          { fieldType: 'Typography', label: 'Deed Tax - State', gridSize: 2,variant: 'rowText' },
          { fieldType: 'CurrencyTextField', name: 'deedTaxBuyer', defaultValue: '0.00', gridSize: 3 },
          { fieldType: 'CurrencyTextField', name: 'deedTaxSeller', defaultValue: '300.00', gridSize: 3 },
          { fieldType: 'Typography', label: '$300.00', gridSize: 1, variant: 'rowText' }
        ]
      ]
    }
  ];
  

const RecordingFeeDetails: FC<RecordingFeeDetailsProps> = () => {
    return (
        <RenderRecordingFee recordingFee={formSchema}/>
    );
}

export default RecordingFeeDetails;
