import { useEffect, useRef, EffectCallback, DependencyList } from 'react';
import { deepCompare } from 'utils/helpers';

function useDeepCompareMemoize<T>(value: T): T {
    const ref = useRef<T>();
  
    if (!deepCompare(value, ref.current!)) {
      ref.current = value;
    }
  
    return ref.current!;
  }
  

export function useDeepCompareEffect(callback: EffectCallback, dependencies: DependencyList): void {
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
}
