export const createFeeQuotePayload = (quoteReqPayload: any) => {
  const currentDate = new Date();
  // const timezoneOffset = currentDate.getTimezoneOffset();
  // const sign = timezoneOffset > 0 ? "-" : "+";
  // const hours = Math.floor(Math.abs(timezoneOffset) / 60)
  //   .toString()
  //   .padStart(2, "0");
  // const minutes = (Math.abs(timezoneOffset) % 60).toString().padStart(2, "0");
  // const formattedDate =
  //   currentDate.toISOString().slice(0, -1) + `${sign}${hours}:${minutes}`;

  return {
    QuoteRequest: {
      "@version": "3.0",
      TransactionInformation: {
        TransactionDateTime: currentDate.toLocaleString(),
        ClientReferenceNumber: "",
        StewartReferenceNumber: "", // to be pulled from quote response
        ProviderID: quoteReqPayload.ProviderID,
        CustomerName: "",
        ClosingDate: currentDate.toLocaleString(),
        TransactionTypeCode: quoteReqPayload.transactionType,
        FeeItemTypeList: {
          FeeItemType: [
            "LenderPremium",
            ...(quoteReqPayload.transactionType !== "REFI"
              ? ["OwnerPremium"]
              : []),
            "TitleServiceFees",
            "Recording",
          ],
        },
      },
      PropertyAddress: {
        CityName: quoteReqPayload.city,
        CountyCode: quoteReqPayload.countyCode,
        StateCode: quoteReqPayload.state,
        ZipCode: quoteReqPayload.postalCode,
      },
      PolicyInfo: {
        IsSimultaneous:
          quoteReqPayload.transactionType !== "REFI" ? "True" : "False",
        PolicyList: {
          Policy: [
            {
              FeeType: "LenderPremium",
              PolicyCoverageTypeCode: quoteReqPayload.lenderPolicyCoverageCode,
              Amount: quoteReqPayload.loanAmount,
              NumberOfYearsSinceLastIssue: 0,
              Reissue: {
                PriorPolicyAmount: "",
                PriorPolicyCoverageTypeCode: "",
                PriorPolicyNumberOfYears: 0,
              },
              EndorsementList: {
                Endorsement: quoteReqPayload.lenderEndorsements,
              },
              AdditionalPolicyList: {
                Policy: [],
              },
            },
            ...(quoteReqPayload.transactionType !== "REFI"
              ? [
                  {
                    FeeType: "OwnerPremium",
                    PolicyCoverageTypeCode:
                      quoteReqPayload.ownerPolicyCoverageCode,
                    Amount: quoteReqPayload.salesPrice,
                    NumberOfYearsSinceLastIssue: 0,
                    Reissue: {
                      PriorPolicyAmount: "",
                      PriorPolicyCoverageTypeCode: "",
                      PriorPolicyNumberOfYears: 0,
                    },
                    EndorsementList: {
                      Endorsement: quoteReqPayload.ownerEndorsements,
                    },
                    AdditionalPolicyList: {
                      Policy: [],
                    },
                  },
                ]
              : []),
          ],
        },
      },
      Recording: {
        QuestionList: { Question: [] },
        MortgageNumPages:
          quoteReqPayload.transactionType === "REFI"
            ? quoteReqPayload.DefaultNumberOfPagesMortgageRefi
            : quoteReqPayload.DefaultNumberOfPagesMortgageSale,
        DeedNumPages: quoteReqPayload.DefaultNumberOfPagesDeed,
        ReleaseNumPages: quoteReqPayload.DefaultNumberOfPagesRelease,
        CountyName: quoteReqPayload.county,
        PageRec: quoteReqPayload.PageRec,
        OriginalDebt: "",
        UnpaidBalance: "",
        IsTorren: quoteReqPayload.IsTorren,
        Subjurisdiction: "",
        LandSystem: "",
        FairMarketValue: "",
      },
      Internal: {
        UnderwriterName: "",
        FeeItemName: "",
        Commercial: "",
        Reverse: false,
        UserRole: 0,
        UserEmail: "",
        HostNetworkGUID: null,
      },
    },
  };
};

export const feeQuotePdfPayload = () => {
  const sourceJson = {
    TitleEscrowFeeEstimate: {
      ReferenceId: "a6e32cef-2053-4833-b841-7e34f31fa75e",
      ClientReferenceId: "CLP-5030",
      TransactionTypeCode: "SALE",
      TransactionTypeDescription: "Sale/Purchase with Mortgage",
      SalesPrice: 50000,
      LoanAmount: 2000,
      LeaseholdAmount: 0,
      LeaseholdLoanAmount: 0,
      ConstructionLoanAmount: 0,
      ProjectLoanAmount: 0,
      ShowAdditionalMortgages: "false",
      AdditionalLoanAmounts: [],
      ShowAdditionalLeaseholdMortgages: "false",
      AdditionalLeaseholdLoanAmounts: [],
      DisplaySplits: "true",
      ShowTitleInsurancePremium: "true",
      TitleInsurancePremium: {
        RateManual: {
          IsSimultaneous: "true",
          OwnersPolicyType: "Basic Policy",
          OwnersBuyerAmount: 0,
          OwnersTitleServiceChargeBuyerAmount: 0,
          OwnersSellerAmount: 287.5,
          OwnersTitleServiceChargeSellerAmount: 0,
          OwnersTotalPremium: 287.5,
          OwnersTitleServiceChargeTotalAmount: 0,
          OwnersBuyerTax: 0,
          OwnersSellerTax: 0,
          OwnersTotalTax: 0,
          ShowPriorOwnersPolicy: "false",
          PriorOwnersPolicyType: "Select a Policy",
          PriorOwnersPolicyAmount: 0,
          PriorOwnersPolicyYears: "",
          LeaseholdOwnersPolicyType: "",
          LeaseholdOwnersBuyerAmount: 0,
          LeaseholdOwnersTitleServiceChargeBuyerAmount: 0,
          LeaseholdOwnersSellerAmount: 0,
          LeaseholdOwnersTitleServiceChargeSellerAmount: 0,
          LeaseholdOwnersTotalPremium: 0,
          LeaseholdOwnersTitleServiceChargeTotalAmount: 0,
          LeaseholdOwnersBuyerTax: 0,
          LeaseholdOwnersSellerTax: 0,
          LeaseholdOwnersTotalTax: 0,
          ShowPriorLeaseholdOwnersPolicy: false,
          PriorLeaseholdOwnersPolicyType: "",
          PriorLeaseholdOwnersPolicyAmount: 0,
          PriorLeaseholdOwnersPolicyYears: "",
          LendersPolicyType: "Basic Policy",
          LendersBuyerAmount: 25,
          LendersTitleServiceChargeBuyerAmount: 0,
          LendersSellerAmount: 0,
          LendersTitleServiceChargeSellerAmount: 0,
          LendersTotalPremium: 25,
          LendersTitleServiceChargeTotalAmount: 0,
          LendersBuyerTax: 0,
          LendersSellerTax: 0,
          LendersTotalTax: 0,
          ShowPriorLoanPolicy: "false",
          PriorLoanPolicyType: "Select a Policy",
          PriorLoanPolicyAmount: 0,
          PriorLoanPolicyYears: "",
          LeaseholdLendersPolicyType: "",
          LeaseholdLendersBuyerAmount: 0,
          LeaseholdLendersTitleServiceChargeBuyerAmount: 0,
          LeaseholdLendersSellerAmount: 0,
          LeaseholdLendersTitleServiceChargeSellerAmount: 0,
          LeaseholdLendersTotalPremium: 0,
          LeaseholdLendersTitleServiceChargeTotalAmount: 0,
          LeaseholdLendersBuyerTax: 0,
          LeaseholdLendersSellerTax: 0,
          LeaseholdLendersTotalTax: 0,
          ShowPriorLeaseholdLendersPolicy: false,
          PriorLeaseholdLendersPolicyType: "",
          PriorLeaseholdLendersPolicyAmount: 0,
          PriorLeaseholdLendersPolicyYears: "",
          ConstructionLendersPolicyType: "",
          ConstructionLendersBuyerAmount: 0,
          ConstructionLendersSellerAmount: 0,
          ConstructionLendersTotalPremium: 0,
          PriorConstructionLendersPolicyType: "",
          PriorConstructionLendersPolicyAmount: 0,
          PriorConstructionLendersPolicyYears: 0,
          ShowPriorConstructionLendersPolicy: false,
          ConstructionLendersTitleServiceChargeBuyerAmount: 0,
          ConstructionLendersTitleServiceChargeSellerAmount: 0,
          ConstructionLendersTitleServiceChargeTotalAmount: 0,
          ConstructionLendersBuyerTax: 0,
          ConstructionLendersSellerTax: 0,
          ConstructionLendersTotalTax: 0,
          ProjectLendersPolicyType: "",
          ProjectLendersBuyerAmount: 0,
          ProjectLendersSellerAmount: 0,
          ProjectLendersTotalPremium: 0,
          PriorProjectLendersPolicyType: "",
          PriorProjectLendersPolicyAmount: 0,
          PriorProjectLendersPolicyYears: 0,
          ShowPriorProjectLendersPolicy: false,
          ProjectLendersTitleServiceChargeBuyerAmount: 0,
          ProjectLendersTitleServiceChargeSellerAmount: 0,
          ProjectLendersTitleServiceChargeTotalAmount: 0,
          ProjectLendersBuyerTax: 0,
          ProjectLendersSellerTax: 0,
          ProjectLendersTotalTax: 0,
        },
        AdditionalRateManualLenderPremiums: [],
        AdditionalRateManualLeaseholdLenderPremiums: [],
        ShowTRID: "true",
        TRID: {
          TRIDOwnersBuyerAmount: 0,
          TRIDOwnersSellerAmount: 212.5,
          TRIDOwnersTotalPremium: 212.5,
          TRIDOwnersBuyerTax: 0,
          TRIDOwnersSellerTax: 0,
          TRIDOwnersTotalTax: 0,
          TRIDLendersBuyerAmount: 100,
          TRIDLendersSellerAmount: 0,
          TRIDLendersTotalPremium: 100,
          TRIDLendersBuyerTax: 0,
          TRIDLendersSellerTax: 0,
          TRIDLendersTotalTax: 0,
          TRIDConstructionLendersBuyerAmount: 0,
          TRIDConstructionLendersSellerAmount: 0,
          TRIDConstructionLendersTotalPremium: 0,
          TRIDConstructionLendersBuyerTax: 0,
          TRIDConstructionLendersSellerTax: 0,
          TRIDConstructionLendersTotalTax: 0,
          TRIDProjectLendersBuyerAmount: 0,
          TRIDProjectLendersSellerAmount: 0,
          TRIDProjectLendersTotalPremium: 0,
          TRIDProjectLendersBuyerTax: 0,
          TRIDProjectLendersSellerTax: 0,
          TRIDProjectLendersTotalTax: 0,
          TRIDBuyerTotal: 100,
          TRIDSellerTotal: 212.5,
          TRIDTotal: 312.5,
          TRIDAdjustment: 75,
        },
        AdditionalTRID: [],
      },
      ShowEndorsement: "true",
      EndorsementsOtherProducts: {
        OwnersEndorsementList: [
          {
            Description: "ALTA 7 Manufacturing Housing Unit",
            TotalAmount: 25,
            BuyerAmount: 0,
            SellerAmount: 25,
            TaxAmount: 0,
            BuyerTaxAmount: 0,
            SellerTaxAmount: 0,
            DisplaySplits: "true",
          },
          {
            Description: "STG Survey End",
            TotalAmount: 25,
            BuyerAmount: 0,
            SellerAmount: 25,
            TaxAmount: 0,
            BuyerTaxAmount: 0,
            SellerTaxAmount: 0,
            DisplaySplits: "true",
          },
          {
            Description: "STG Navigational Servitude End",
            TotalAmount: 31.25,
            BuyerAmount: 0,
            SellerAmount: 31.25,
            TaxAmount: 0,
            BuyerTaxAmount: 0,
            SellerTaxAmount: 0,
            DisplaySplits: "true",
          },
        ],
        LendersEndorsementList: [
          {
            Description: "ALTA 8.1 Environmental Protection Lien",
            TotalAmount: 25,
            BuyerAmount: 25,
            SellerAmount: 0,
            TaxAmount: 0,
            BuyerTaxAmount: 0,
            SellerTaxAmount: 0,
            DisplaySplits: "true",
          },
          {
            Description: "Florida Form 9",
            TotalAmount: 31.25,
            BuyerAmount: 31.25,
            SellerAmount: 0,
            TaxAmount: 0,
            BuyerTaxAmount: 0,
            SellerTaxAmount: 0,
            DisplaySplits: "true",
          },
          {
            Description: "ALTA 6 Variable Rate Mortgage",
            TotalAmount: 25,
            BuyerAmount: 25,
            SellerAmount: 0,
            TaxAmount: 0,
            BuyerTaxAmount: 0,
            SellerTaxAmount: 0,
            DisplaySplits: "true",
          },
          {
            Description: "ALTA 5.1 Planned Unit Development",
            TotalAmount: 25,
            BuyerAmount: 25,
            SellerAmount: 0,
            TaxAmount: 0,
            BuyerTaxAmount: 0,
            SellerTaxAmount: 0,
            DisplaySplits: "true",
          },
          {
            Description: "ALTA 4.1 Condominium",
            TotalAmount: 25,
            BuyerAmount: 25,
            SellerAmount: 0,
            TaxAmount: 0,
            BuyerTaxAmount: 0,
            SellerTaxAmount: 0,
            DisplaySplits: "true",
          },
          {
            Description: "ALTA 6.2 Variable Rate Mortgage-Negative Amorti",
            TotalAmount: 25,
            BuyerAmount: 25,
            SellerAmount: 0,
            TaxAmount: 0,
            BuyerTaxAmount: 0,
            SellerTaxAmount: 0,
            DisplaySplits: "true",
          },
        ],
        LeaseholdOwnersEndorsementList: [],
        LeaseholdLendersEndorsementList: [],
        AdditionalLendersEndorsementList: [],
        AdditionalLeaseholdLendersEndorsementList: [],
        ConstructionLendersEndorsementList: [],
        ProjectLendersEndorsementList: [],
        LendersBuyerEndorsementTax: 0,
        LendersSellerEndorsementTax: 0,
        LendersTotalEndorsementTax: 0,
        OwnersBuyerEndorsementTax: 0,
        OwnersSellerrEndorsementTax: 0,
        OwnersTotalEndorsementTax: 0,
        LeaseholdLendersBuyerEndorsementTax: 0,
        LeaseholdLendersSellerEndorsementTax: 0,
        LeaseholdLendersTotalEndorsementTax: 0,
        LeaseholdOwnersBuyerEndorsementTax: 0,
        LeaseholdOwnersSellerrEndorsementTax: 0,
        LeaseholdOwnersTotalEndorsementTax: 0,
        ConstructionLendersBuyerEndorsementTax: 0,
        ConstructionLendersSellerrEndorsementTax: 0,
        ConstructionLendersTotalEndorsementTax: 0,
        ProjectLendersBuyerEndorsementTax: 0,
        ProjectLendersSellerEndorsementTax: 0,
        ProjectLendersTotalEndorsementTax: 0,
        TotalBuyerPremiumAndEndorsementPreTax: 181.25,
        TotalSellerPremiumAndEndorsementPreTax: 368.75,
        TotalPremiumAndEndorsementPreTax: 550,
        TotalBuyerPremiumAndEndorsementTax: 0,
        TotalSellerPremiumAndEndorsementTax: 0,
        TotalPremiumAndEndorsementTax: 0,
        TotalBuyerPremiumAndEndorsement: 181.25,
        TotalSellerPremiumAndEndorsement: 368.75,
        TotalPremiumAndEndorsement: 550,
      },
      ShowTitleServiceFee: "true",
      TitleServiceFees: {
        TitleServiceFeeList: [
          {
            Description: "Title Loan Tie In",
            TotalAmount: 200,
            BuyerAmount: 200,
            SellerAmount: 0,
            TaxAmount: 0,
            BuyerTaxAmount: 0,
            SellerTaxAmount: 0,
            DisplaySplits: "true",
          },
          {
            Description: "Title Settlement Fee",
            TotalAmount: 1310,
            BuyerAmount: 795,
            SellerAmount: 515,
            TaxAmount: 0,
            BuyerTaxAmount: 0,
            SellerTaxAmount: 0,
            DisplaySplits: "true",
          },
        ],
        TotalBuyerTax: 0,
        TotalSellerTax: 0,
        TotalTax: 0,
        TotalBuyerAmountPreTax: 995,
        TotalSellerAmountPreTax: 515,
        TotalAmountPreTax: 1510,
        TotalBuyerAmountIncludeTax: 995,
        TotalSellerAmountIncludeTax: 515,
        TotalAmountIncludeTax: 1510,
      },
      ShowRecordingFeesTransferTaxes: "true",
      RecordingFeesTransferTaxes: {
        RecordingFeesTransferTaxesList: [
          {
            Description: "Mortgage Recording Fee",
            TotalAmount: 214,
            BuyerAmount: 0,
            SellerAmount: 0,
            LenderAmount: 0,
            DisplaySplits: "true",
          },
          {
            Description: "Deed Recording Fee",
            TotalAmount: 52.5,
            BuyerAmount: 0,
            SellerAmount: 0,
            LenderAmount: 0,
            DisplaySplits: "true",
          },
          {
            Description: "Mortgage Tax - State",
            TotalAmount: 7,
            BuyerAmount: 7,
            SellerAmount: 0,
            LenderAmount: 0,
            DisplaySplits: "true",
          },
          {
            Description: "Mortgage Intangible Tax - State",
            TotalAmount: 4,
            BuyerAmount: 4,
            SellerAmount: 0,
            LenderAmount: 0,
            DisplaySplits: "true",
          },
          {
            Description: "Deed Tax - State",
            TotalAmount: 350,
            BuyerAmount: 0,
            SellerAmount: 350,
            LenderAmount: 0,
            DisplaySplits: "true",
          },
        ],
        TotalBuyerRecordingFeesTransferTaxes: 11,
        TotalSellerRecordingFeesTransferTaxes: 350,
        TotalLenderRecordingFeesTransferTaxes: 0,
        TotalRecordingFeesTransferTaxes: 627.5,
      },
      PreparedInformation: {
        Logo: "",
        BorrowerName: "",
        PropertyStreetAddress: "",
        ByName: "",
        ByCompany: "",
        ByAddress: "",
        ByCsz: "",
        ByEmail: "",
        ByPhone: "",
        ForName: "",
        ForCompany: "",
        ForEmail: "",
        ForNote: "",
        FooterInformation: "",
        CreatedByApp: "Stewart Rate Calculator",
      },
      PropertyInformation: {
        PropertyState: "FL",
        PropertyCity: "Inverness",
        PropertyCounty: "Citrus",
        PropertyZipCode: "34450",
        StateNotes:
          "These rates are for residential policies only.  Please contact your local underwriter for commercial rates calculations. This site cannot be used for Simultaneous Issue computations under TRID.\nIf you need assistance with this site, please contact: EnterprisePricingSolutionTeam@stewart.com.\t",
      },
      SettlementOffice: {
        OfficeName: "Stewart Title Company",
        OfficeAddress: "1900 South State College Blvd., Suite 200",
        OfficeState: "CA",
        OfficeCity: "Anaheim",
        OfficeZipCode: "92806",
        OfficeContactName: "Tier 1-3:Team 32",
        OfficeEmail: "team32@stewart.com",
        OfficePhone: "(P)(949)565-3586",
        OfficeFax: "(F)",
        ProviderNotes:
          "Title Notary Fee include in Settlement Fee.  Tax Certificate included in Title Premium.",
      },
      Totals: {
        SectionBorCTotal: 1251.25,
        SectionETotal: 11,
        SectionHTotal: 0,
        GrandTotal: 1262.25,
      },
    },
  };

  // Step 1: Convert JSON object to string
  const jsonString = JSON.stringify(sourceJson);

  // Step 2: Convert JSON string to Uint8Array
  const utf8Encoder = new TextEncoder();
  const uint8Array = utf8Encoder.encode(jsonString);

  // Step 3: Encode Uint8Array to Base64
  const base64String = btoa(String.fromCharCode(...uint8Array));

  return {
    StewartDocTemplate: {
      Template: "",
      SourceData: base64String,
      SourceFormat: "JSON",
      OutputFormat: "PDF",
    },
  };
};
