import { statusStringsConstants } from "constants/strings";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import loandData from "redux/dummyDatas/loanData.json";
// import OptionsData from "redux/dummyDatas/optionsData.json";

const initialState: Record<string, any> = {
  data: location?.host?.includes("localhost") ? loandData : {},
  transactionObject: {},
  richTexts: statusStringsConstants.importantNotes,
  createdAt: "",
  isCancelled: false,
  options: {
    IsCTS: true,
    clientFileNumber: "",
    isOrderPlaced: false,
    status: [],
    IsCancelled: false,
    clientID: "",
    StewartContextID: "",
    Notes: [],
    Documents: [],
    rwDocs: [],
    localOfficeAddress: {},
    ctsOffice: {},
    PayoffInformation: { PayoffLenders: { PayoffLender: [] } },
    payoffSelectedRows: [],
    payoffSelectedTypes: {},
    isOrderCanceled: false,
    productType: "",
    selectedLoanType: "",
    Contacts: null,
    Address: null,
    fileNumber: "",
  },
  transactionStatus: false,
  isExistingTransation: false,
  epcResources: [],
  userSelectedResources: [],
  lenderSettings: {},
  selctedRadio: "Centralized",
};

export const loadnData: any = createSlice({
  name: "loanData",
  initialState,
  reducers: {
    reset: () => initialState,

    setLoanData: (state, action: PayloadAction<Record<string, any>>) => {
      const volsArray = action.payload.loan.applications?.[0]?.vols || [];
      const filterWillPaidOff = volsArray.length
        ? volsArray.filter(
            (_: any) =>
              _.payoffStatusIndicator &&
              _.liabilityType &&
              ["MortgageLoan", "HELOC"].includes(_.liabilityType)
          ) || []
        : [];

      let modifiedPayoffArr = [];
      if (filterWillPaidOff.length) {
        modifiedPayoffArr =
          filterWillPaidOff.map((vol: any) => ({
            LenderName: vol.holderName,
            PayoffType: "",
            LenderPhone: vol.holderPhone || "",
            LenderFax: vol.holderFax || "",
            LoanAmount: vol?.unpaidBalanceAmount?.toFixed(2) || "",
            LoanNumber: action.payload.loan.loanNumber,
          })) || [];
      }
      return {
        ...state,
        data: action?.payload ?? {},
        options: {
          ...state.options,
          PayoffInformation: {
            PayoffLenders: { PayoffLender: modifiedPayoffArr },
          },
        },
      };
    },
    setContacts: (
      state,
      action: PayloadAction<{
        Contacts: any[];
        Address: any[];
        fileNumber: string;
      }>
    ) => {
      const { Contacts, Address, fileNumber } = action.payload;
      return {
        ...state,
        options: {
          ...state.options,
          Contacts: Contacts,
          Address: Address,
          fileNumber: fileNumber,
        },
      };
    },

    setSeletedRadio: (state, action: PayloadAction<{ payload: string }>) => {
      return {
        ...state,
        options: {
          ...state.options,
          IsCTS:
            typeof action.payload === "string" &&
            action.payload === "Centralized"
              ? true
              : false,
        },
        selctedRadio: action.payload ?? {},
      };
    },
    setisExistingTransation: (
      state,
      action: PayloadAction<Record<string, any>>
    ) => {
      return { ...state, isExistingTransation: action?.payload ?? {} };
    },
    setOptions: (state, action: PayloadAction<Record<string, any>>) => {
      const newNotes = action.payload.Note;
      const newStatus = action.payload.Status;
      state.options = {
        ...state.options,
        ...action.payload,
        ...(action.payload?.Notes && {
          Notes: action.payload.Notes,
        }),
      };

      if (action.payload.hasOwnProperty("Note")) {
        if (Array.isArray(newNotes)) {
          state.options.Notes.push(...newNotes);
        } else {
          state.options.Notes.push(newNotes);
        }
        Reflect.deleteProperty(state.options, "Note");
      }
      if (action.payload.hasOwnProperty("Status") && newStatus) {
        if (Array.isArray(newStatus)) {
          state.options.status = [...state.options.status, ...newStatus];
        } else {
          state.options.status = [...state.options.status, newStatus];
        }
        Reflect.deleteProperty(state.options, "Status");
      }
    },

    setTransactionObject: (
      state,
      action: PayloadAction<Record<string, any>>
    ) => {
      return { ...state, transactionObject: action?.payload ?? {} };
    },
    setTransactionId: (state, action: PayloadAction<Record<string, any>>) => {
      return {
        ...state,
        transactionObject: {
          ...state.transactionObject,
          transactionId: action.payload ?? "",
        },
      };
    },

    setTransactionResources: (
      state,
      action: PayloadAction<Record<string, any>>
    ) => {
      return {
        ...state,
        epcResources: action.payload?.epcResources,
        options: action?.payload.options ?? {},
        createdAt: action.payload.createdAt,
        transactionStatus: action.payload.iscancelled,
      };
    },

    setNotes: (state, action: PayloadAction<Record<string, any>>) => {
      return { ...state, richTexts: action?.payload ?? "" };
    },
    setProductTypes: (state, action: PayloadAction<Record<string, any>>) => {
      return {
        ...state,
        options: {
          ...state.options,
          productType: action.payload,
        },
      };
    },
    setTransactionStatus: (
      state,
      action: PayloadAction<Record<string, any>>
    ) => {
      return { ...state, transactionStatus: action?.payload ?? "" };
    },
    setPropertyType: (state, action: PayloadAction<Record<string, any>>) => {
      const { Vesting = "", ParcelID = "", Legal = "" } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          loan: {
            ...state.data.loan,
            closingDocument: {
              ...state.data.loan.closingDocument,
              ...(Vesting && {
                finalVestingDescription: Vesting,
              }),
              ...(Legal && {
                textDescription: Legal,
              }),
            },
            property: {
              ...state.data.loan.property,
              ...(ParcelID && {
                assessorsParcelIdentifier: ParcelID,
              }),
            },
          },
        },
      };
    },
    resetPropertyType: (state) => {
      const defaultVestingDescription = "";
      const defaultParcelID = "";
      const defaultLegal = "";

      return {
        ...state,
        data: {
          ...state.data,
          closingDocument: {
            ...state.data.closingDocument,
            finalVestingDescription: defaultVestingDescription,
            textDescription: defaultLegal,
          },
          property: {
            ...state.data.property,
            assessorsParcelIdentifier: defaultParcelID,
          },
        },
      };
    },

    setComm: (state, action) => {
      return {
        ...state,
        options: {
          ...state.options,
          Notes: [...(state.options.Notes || []), { ...action.payload }],
        },
      };
    },

    setRWDocs: (state, action: PayloadAction<{}[]>) => {
      return {
        ...state,
        options: {
          ...state.options,
          rwDocs: [...(state.options.rwDocs || []), ...action.payload],
        },
      };
    },

    setPayoffList: (
      state,
      action: PayloadAction<{ list: {}[]; rows: []; types: {} }>
    ) => {
      return {
        ...state,
        options: {
          ...state.options,
          PayoffInformation: {
            PayoffLenders: {
              PayoffLender: action.payload.list,
            },
          },
          payoffSelectedRows: action.payload.rows,
          payoffSelectedTypes: action.payload.types,
        },
      };
    },

    setDocuments: (
      state,
      action: PayloadAction<
        {
          DOCUMENT: {
            DocumentContent: string;
            DocumentDateTime: string;
            DocumentDescription: string;
            DocumentIdentifier: number;
            DocumentName: string;
            DocumentType: string;
            EncodeType: string;
            FileName: string;
            FileType: string;
          };
        }[]
      >
    ) => {
      return {
        ...state,
        options: {
          ...state.options,
          Documents: [...action.payload, ...(state.options.Documents || [])],
        },
      };
    },

    setUserSelectedResources: (
      state,
      action: PayloadAction<Record<string, any>>
    ) => {
      return {
        ...state,
        userSelectedResources: action?.payload || [],
      };
    },

    setLoanType: (state, action: PayloadAction<Record<string, any>>) => {
      return {
        ...state,
        options: {
          ...state.options,
          selectedLoanType: action.payload || "",
        },
      };
    },
    setContractId: (state, action: PayloadAction<Record<string, any>>) => {
      return {
        ...state,
        options: {
          ...state.options,
          contractId: action.payload || "",
        },
      };
    },

    setLocalOfficeAddress: (
      state,
      action: PayloadAction<Record<string, any>>
    ) => {
      return {
        ...state,
        options: {
          ...state.options,
          IsCTS: false,
          localOfficeAddress: action.payload || {},
        },
      };
    },
  },
});

export const {
  setLoanData,
  reset,
  setOptions,
  setTransactionObject,
  setTransactionId,
  setTransactionResources,
  setPropertyType,
  resetPropertyType,
  setNotes,
  setComm,
  setDocuments,
  setPayoffList,
  setRWDocs,
  setUserSelectedResources,
  setLoanType,
  setLocalOfficeAddress,
  setSeletedRadio,
  setisExistingTransation,
  setTransactionStatus,
  setProductTypes,
  setContacts,
  setContractId,
} = loadnData.actions;
export default loadnData.reducer;
