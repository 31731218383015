import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: Record<string, any> = {
  options: {
    feeList: [],
    clientRefId: "",
  },
  pricingQuotes: {},
  transactionID: "",
};

export const feeQuoteSlice: any = createSlice({
  name: "feeQuotes",
  initialState,
  reducers: {
    reset: () => initialState,
    setFeeQuotesOptions: (
      state,
      action: PayloadAction<Record<string, any>>
    ) => {
      return { ...state, options: action?.payload ?? {} };
    },
    setPricingQuotes: (state, action: PayloadAction<Record<string, any>>) => {
      return { ...state, pricingQuotes: action?.payload ?? {} };
    },
    setFeeList: (state, action: PayloadAction<Record<string, any>[]>) => {
      return {
        ...state,
        options: {
          ...state.options,
          feeList: action.payload,
        },
      };
    },
    setpriceQuoteTransactionID: (
      state,
      action: PayloadAction<Record<string, any>>
    ) => {
      return { ...state, transactionID: action?.payload ?? {} };
    },
    setClientRefId: (state, action: PayloadAction<Record<string, any>>) => {
      return {
        ...state,
        options: {
          ...state.options,
          clientRefId: action.payload,
        },
      };
    },
   
  },
});

export const {
  setFeeQuotesOptions,
  setPricingQuotes,
  setFeeList,
  setpriceQuoteTransactionID,
  setClientRefId,
} = feeQuoteSlice.actions;
export default feeQuoteSlice.reducer;
