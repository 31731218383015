// loader.js
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LoanparamsState {
  data: Record<string, string>;
}

const initialState: LoanparamsState = {
    data: {}
};

const loanParams = createSlice({
    name: "loanParams",
    initialState,
    reducers: {
        reset: () => initialState,
        setLoanParams: (state, action: PayloadAction<Record<string, any>>) => {
            return { ...state, data: action?.payload ?? {} };
        }
    }
});

export const { setLoanParams } = loanParams.actions;
export default loanParams.reducer;
