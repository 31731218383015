import { Link, Typography, Card, Box, Container } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "interfaces/rootState";
import StewartIcon from "assets/images/stewartlogo.png";

const SetupLender = () => {
  const {
    data: {
      lenderClientName = "",
      lenderPhoneNumber = "",
      clientID = "",
      propertyStreet = "",
      propertyZipCode = "",
      propertyState = "",
      propertyCity = "",
      propertyCounty = "",
    },
  } = useSelector((st: RootState) => st.loanParams) || {};
  const lenderAddress = `${propertyStreet} ${propertyCounty}, ${propertyState} ${propertyZipCode}`;
  const phoneNum = lenderPhoneNumber?.includes("null") ? "" : lenderPhoneNumber;
  return (
    <Box mt={4} mx="auto">
      <img
        src={StewartIcon}
        alt="StewartIcon"
        style={{ width: 235, height: 40, marginLeft: 20 }}
      />
      <Container maxWidth="sm">
        <br />
        <br />

        <Card
          sx={{
            borderColor: "snow",
            boxShadow: "none",
            background: "#f5f5f5",
          }}
        >
          <Box p={4} display="flex" flexDirection="column" overflow="hidden">
            <Box display="flex" justifyContent="center">
              <img
                src="lock.png"
                alt="lock image"
                style={{ paddingTop: "20px" }}
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              marginBottom={2}
              sx={{ fontFamily: "unset" }}
            >
              <h3>Request Access</h3>
              <p>Your Company has not been setup with Stewart Title.</p>
              <p>
                Please click &nbsp;
                <a
                  href={`mailto:lenderregistration@stewart.com?subject=Lender%20Registration%20with%20Stewart%20Title%20&body=We%20are%20interested%20in%20placing%20Title/Closing%20orders%20to%20Stewart%20Title%20through%20Encompass.%0A%0APlease%20contact%20the%20following%20if%20further%20information%20is%20required%3A%0A%0ALender%20Name%3A%20${encodeURI(
                    lenderClientName
                  )}%0ALender%20Address%3A%20${lenderAddress}%0ALender%20Phone%3A%20${phoneNum}%0AEncompass%20Client%20Id%3A%20${clientID}%0A%0AStewart%20Sales%20Rep%3A%0ANMLS%20ID%20(optional)%3A%0APrimary%20Contact%20(required)%3A%0A%0AName%3A%0APhone%3A%0AEmail%3A`}
                  style={{ color: "blue", textDecoration: "underline" }}
                >
                  Request Access
                </a>
                &nbsp; link, fill and submit the email.
              </p>
            </Box>

            {/* <Box display="flex" justifyContent="flex-end">
              <Link href="/">Go Back</Link>
            </Box> */}
          </Box>
        </Card>
      </Container>
    </Box>
  );
};

export default SetupLender;
