
// ** MUI Imports
import { Theme } from '@mui/material/styles';

const Card = (theme: Theme) => {
    return {
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    // eslint-disable-next-line max-len
                    background: 'linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.13)), radial-gradient(90% 7% at 50% 8%, rgba(255, 255, 255, 0.47) 25%, rgba(255, 255, 255, 0) 50%), #0075AA',
                    border: `${theme.spacing(0.3)} solid #0075AA`, boxShadow: `0 0 20px rgba(0, 0, 0, 1)`,
                    textAlign: 'center',
                    color: theme.palette.common.white


                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    border: `${theme.spacing(0.6)} solid #0075AA`,
                    borderRadius: theme.spacing(0.6)
                } }
        }

    };
};

export default Card;
