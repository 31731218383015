import Box from "@mui/material/Box";
import CircularProgress, {
  circularProgressClasses,
  CircularProgressProps,
} from "@mui/material/CircularProgress";

function FacebookCircularProgress(props: CircularProgressProps) {
  return (
    <Box sx={{ position: "relative" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) =>
            theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={40}
        thickness={3}
        {...props}
      />
    </Box>
  );
}

const FullScreenLoader = () => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    height="100vh"
    width="100%"
    zIndex="99999"
    sx={{
      position: "fixed",
      top: 0,
      left: 0,
      background: "rgba(255, 255, 255, 0.8)",
    }}
  >
    {/* <CircularProgress /> */}
    <Box
      display="grid"
      sx={{
        height: "60px",
        width: "60px",
        placeItems: "center",
        background: "aliceblue",
        borderRadius: "50px",
        padding: "10px",
      }}
    >
      <FacebookCircularProgress />
    </Box>
  </Box>
);

export default FullScreenLoader;
