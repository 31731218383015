import React, { useEffect, useState } from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Box,
  Button,
} from "@mui/material";
import EPCInput from "componnets/atoms/EPCInput";
import { BpRadio } from "componnets/atoms/radiogroup";
import {
  // setFilteredBusinessData,
  setSelected,
} from "redux/features/srcSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "interfaces/rootState";

interface BusinessLocation {
  ID: string;
  ProviderCode: string;
  Name: string;
  Address: string;
  City: string;
  StateCode: string;
  ZipCode: string;
  Phone: string;
  ProximityFromProperty: string;
  IsProviderWithFee: string;
}
interface BusinessListProps {
  handleProviderSelect: any;
  onClose: any;
  filteredBusinessData: any;
  setFilteredBusinessData: any;
}

const BusinessList: React.FC<BusinessListProps> = ({
  handleProviderSelect,
  onClose,
  filteredBusinessData,
  setFilteredBusinessData,
}) => {
  const dispatch = useDispatch();
  const businessData = useSelector((state: RootState) => state.src.providers);
  // const searchTerm = useSelector((state: RootState) => state.src.searchTerm);
  // const filteredBusinessData = useSelector((state: RootState) => state.src.filteredBusinessData);
  const [searchTerm, setSearchTerm] = useState("");

  const selected = useSelector((state: RootState) => state.src.selected);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchClick = () => {
    const filteredData = businessData.filter((location: BusinessLocation) => {
      const searchTermLower = searchTerm.toLowerCase();
      return (
        location.Name?.toLowerCase().includes(searchTermLower) ||
        location.Address?.toLowerCase().includes(searchTermLower) ||
        location.City?.toLowerCase().includes(searchTermLower) ||
        location.StateCode?.toLowerCase().includes(searchTermLower) ||
        location.ZipCode?.toLowerCase().includes(searchTermLower) ||
        location.Phone?.toLowerCase().includes(searchTermLower) ||
        location.ProviderCode?.toLowerCase().includes(searchTermLower)
      );
    });
    setFilteredBusinessData(filteredData);
  };

  const handleRadioChage = (event: any) => {
    dispatch(setSelected(parseInt(event.target.value, 10)));
  };

  useEffect(() => {
    handleSearchClick();
  }, [searchTerm]);

  return (
    <Box>
      <Box mb={2} border={1} borderColor="#BDBDBD" borderRadius="4px">
        <Box display="flex" alignItems="center" m={2}>
          <EPCInput
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <Button
            variant="contained"
            onClick={handleSearchClick}
            sx={{ ml: 1 }}
          >
            Search
          </Button>
        </Box>
        <List disablePadding sx={{ height: "62vh", overflowY: "auto" }}>
          {filteredBusinessData.map((location: any, index: any) => (
            <React.Fragment key={index}>
              <ListItem sx={{ padding: 0 }}>
                <ListItemIcon>
                  <BpRadio
                    value={index}
                    checked={selected === index}
                    onChange={handleRadioChage}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={<Typography>{location.Name}</Typography>}
                  secondary={
                    <>
                      <Typography variant="body2">
                        {location.Address}
                      </Typography>
                      <Typography variant="body2">
                        Tel: {location.Phone}
                      </Typography>
                    </>
                  }
                />
                <ListItemText
                  primary={
                    location.ProximityFromProperty && (
                      <Typography
                        variant="body2"
                        sx={{ float: "right", pr: 4 }}
                      >
                        {location.ProximityFromProperty} miles
                      </Typography>
                    )
                  }
                />
              </ListItem>
              {index < filteredBusinessData.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </Box>
      <Box display="flex" justifyContent="flex-start">
        <Button
          variant="contained"
          color="primary"
          onClick={handleProviderSelect}
        >
          Continue
        </Button>
        &nbsp;&nbsp;
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default BusinessList;
