export const getMissingFields = (srcParams: Record<string, string>) => {
  let missingFields = "";

  if (!srcParams) {
    return "Loan Number, Property Address, Sale Price, Loan Amount, Loan Purpose Type, County are required!";
  }

  for (const field of Object.keys(srcParams)) {
    if (!srcParams[field]) {
      const label = field
        .replace(/([A-Z])/g, " $1")
        .replace(/^./, (str) => str.toUpperCase());
      missingFields += `${label}, `;
    }
  }

  // Remove trailing comma and space if there are any missing fields
  if (missingFields.endsWith(", ")) {
    missingFields = missingFields.slice(0, -2);
  }

  return missingFields;
};
