import { RootState } from "interfaces/rootState";

export const getGuidData = (state: RootState) => {
  const {
    loan: { options: { IsCTS } = { IsCTS: undefined } } = {},
    officeData: {
      officeData: {
        Stewart: { ContractInfo: { PricingGuid = "" } = {} } = {},
      } = {},
    } = {},
  } = state || {};
  const isCTSValue = IsCTS ?? false;

  return { PricingGuid, IsCTS: isCTSValue };
};
