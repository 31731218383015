import type { FC } from "react";
import type React from "react";
import Radio from "@mui/material/Radio";
import { RadioGroup, FormControlLabel, FormControl } from "@mui/material";
import { styled } from "@mui/material/styles";

interface RadiogroupProps {
  labelsSet?: string[];
  sx?: React.CSSProperties;
  onOptionSelect?: (value: string) => void;
  defaultValue?: string;
  disabled?: boolean;
  row?: boolean;
  hideCentralized?: boolean; // needed if contractId is 1 & transactionType is "Other"
}

const BpIcon = styled("span")(() => ({
  borderRadius: "60%",
  width: 14,
  height: 14,
  boxShadow:
    "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: "#f5f8fa",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: "#ebf1f5",
  },

  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 14,
    height: 14,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:not([disabled]):hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

export function BpRadio(props: any) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      size="small"
      {...props}
    />
  );
}

const Radiogroup: FC<RadiogroupProps> = ({
  labelsSet,
  onOptionSelect,
  defaultValue = "",
  disabled = false,
  row = false,
  hideCentralized = false,
}) => {
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (onOptionSelect) onOptionSelect(e.target.value);
  }
  return (
    <FormControl>
      <RadioGroup
        row={row}
        name="radio-buttons-group"
        value={defaultValue}
        onChange={handleChange}
      >
        {labelsSet?.map((cv, index) => {
          return (
            <FormControlLabel
              sx={{
                display: hideCentralized && index === 0 ? "none" : "block",
              }}
              disabled={disabled}
              key={index}
              value={cv}
              control={<BpRadio />}
              label={cv}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default Radiogroup;
