import type React from "react";
import { Box, Typography } from "@mui/material";
import StewartIcon from "assets/images/stewartlogo.png";
import HeaderButtonList from "componnets/organisms/header/HeaderButtonList";
import { useSelector } from "react-redux";
import { RootState } from "interfaces/rootState";

const Header: React.FC = () => {
  const data = useSelector((state: RootState) => state.loan.data) ?? {};
  const { loan = {}, originatingParty = {} } = data;
  const user = originatingParty?.pointOfContact?.name;
  const { loanNumber } = loan;

  return (
    <Box width="98%" display="flex">
      <Box display="flex" alignItems="center" justifyContent="center">
        <img src={StewartIcon} alt="StewartIcon" height="40" width="235" />
      </Box>
      <Box
        display="flex"
        alignItems="flex-end"
        gap="2"
        p={2}
        flexDirection="column"
        flex="1"
      >
        <HeaderButtonList />
        <Box display="flex">
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "bold",
              paddingRight: (theme) => theme.spacing(3),
            }}
          >
            Welcome <span style={{ fontWeight: "normal" }}>{user}</span>
          </Typography>

          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            Loan Number{" "}
            <span style={{ fontWeight: "normal" }}>{loanNumber}</span>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
