import { useEffect, useRef } from 'react';
import { isNullOrEmpty } from 'utils/ordervalidation';

type CallbackFunction = () => void;

const useRunOnceAfterValues = <T, >(callback: CallbackFunction, values: Array<string | null | undefined>): void => {
    const hasRun = useRef(false);
    useEffect(() => {
      
        const areAllValuesAvailable = values.every((cv) => !isNullOrEmpty(cv));
        if (areAllValuesAvailable && !hasRun.current) {
            hasRun.current = true;
            callback();
        }
    }, [values, callback]); 
};

export default useRunOnceAfterValues;
