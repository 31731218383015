import { createBreakpoints } from "@mui/system";


const breakpoints = createBreakpoints({
    values: {
        xs: 0,
        sm: 800,
        md: 900,
        lg: 1200,
        xl: 1536,
    },
});

export default breakpoints