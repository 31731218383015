import Link from '@mui/material/Link';
import type React from 'react';
import type { SxProps } from '@mui/system'; 
import { Theme } from '@mui/material/styles';


interface ButtonLinkProps {
    label: string;
    onClick?: () => void;
    sx?: SxProps<Theme>; 
}

const ButtonLink: React.FC<ButtonLinkProps> = ({ label, onClick, sx }) => { 
    return (
        <Link
            component="button"
            variant="body2"
            onClick={onClick}
            sx={{ 
                color: (theme) => theme.palette.customColors.lightBlue,
                ...sx  
            }}
        >
            {label}
        </Link>
    );
};

export default ButtonLink;
