// ** MUI Imports
import { Theme } from '@mui/material/styles';


const Button = (theme: Theme) => {
    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    fontSize: 14,
                    lineHeight: 1.71,
                    letterSpacing: '1px',
                    textTransform: "none",
                    borderRadius: 0

                },

                outlined: {
                    padding: `${theme.spacing(1.625, 5.25)}`,
                    background:'transparent',
                    border :`1px solid ${theme.palette.customColors.buttonColor}`,
                    borderRadius:'4px',
                    color:theme.palette.customColors.buttonColor
                },
                contained: {
                    background: theme.palette.customColors.buttonColor,

                    '&:hover': {
                        background: theme.palette.customColors.hover
                    },
                    '&:disabled': {
                        background: theme.palette.grey[300],
                        color: theme.palette.common.black

                    }
                },

                sizeSmall: {
                    padding: `${theme.spacing(1, 2.25)}`,
                    '&.MuiButton-contained': {
                        padding: `${theme.spacing(1, 3.5)}`
                    },
                    '&.MuiButton-outlined': {
                        padding: `${theme.spacing(0.75, 3.25)}`
                    }
                },
                sizeLarge: {
                    padding: `${theme.spacing(2.125, 5.5)}`,
                    '&.MuiButton-contained': {
                        padding: `${theme.spacing(2.125, 6.5)}`
                    },
                    '&.MuiButton-outlined': {
                        padding: `${theme.spacing(1.875, 6.25)}`
                    }
                }
            }

        }

    };
};

export default Button;
