import CustomAccordion from 'componnets/molecules/Accordion';
import type { FC } from 'react';
import RecordingFeeDetails from './RecordingFeeDetails';

interface RecordingfeeProps {}

const Recordingfee: FC<RecordingfeeProps> = () => {
    return (
        <CustomAccordion
        summary='Recording Fees and Taxes'
        >
        <RecordingFeeDetails/>
        </CustomAccordion>
    );
}

export default Recordingfee;
