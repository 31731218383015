import React from 'react';
import { RenderEndorsement } from 'componnets/services/RenderForms';




  

  interface EndorsementTypes {
    fieldType:string;
    label?: string;
    required?: boolean;
    disabled?: boolean;
    hidden?: boolean;
    options?: string[];
    variant?: string;
    onClick?: () => void;
    typographyVariant?: "h1" | "h2" | "h3" | "body1" | "body2" | "h6"; // Add h6 for the section titles
    align?: "left" | "center" | "right";
  }
  
  export const Endorsement: EndorsementTypes[] = [
    {
      fieldType: "Typography",
      label: "OWNER'S POLICY ENDORSEMENTS",
      variant: "h1",
     align: "left"
    },
    {
      fieldType: "DropDown",
      label: "Select or Type Endorsement",
      required: false,
      disabled: false,
      hidden: false
    },
    {
      fieldType: "AddButton",
      label: "Add Endorsement",
      variant: "contained",
      onClick: () => {
        // Placeholder for button click action
      }
    },
    {
      fieldType: "Typography",
      label: "LENDER'S POLICY ENDORSEMENTS",
      variant: "h1",
      align: "left"
    },
    {
      fieldType: "DropDown",
      label: "ALTA 4.1 Condominium - Current Assessments - FL 7-1-21",
      required: false,
      disabled: false,
      hidden: false
    },
    {
      fieldType: "DropDown",
      label: "ALTA 8.1 Environmental Protection Lien - FL 7-1-21",
      required: false,
      disabled: false,
      hidden: false
    },
    {
      fieldType: "DropDown",
      label: "ALTA 9-06 Restrictions, Encroachments, Minerals - FL - 12-1-2013",
      required: false,
      disabled: false,
      hidden: false
    },
    {
      fieldType: "DropDown",
      label: "Select or Type Endorsement",
      required: false,
      disabled: false,
      hidden: false
    },
    {
        fieldType: "AddButton",
        label: "Add Endorsement",
        variant: "contained",
        onClick: () => {
          // Placeholder for button click action
        }
      },
  ];


  

const Endorsements: React.FC = () => {
  return (
    <>
   <RenderEndorsement endorsements={Endorsement}/>
    </>
  );
};

export default Endorsements;
