import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { fetchCounty } from "componnets/StewartSRC/utils";



 const CountyCodesSlice = createSlice({
  name: 'countyCodes',
  initialState: {
    data: {},
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    countyCode:""
  },
  reducers: {
    setCountyCode: (state, action: PayloadAction<string>) => {
      return { ...state, countyCode: action.payload };
    },
   },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCounty.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCounty.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
        
      })
      .addCase(fetchCounty.rejected, (state) => {
        state.status = 'failed';
      
      });
  }
});
export const {
  setCountyCode
}=CountyCodesSlice.actions
export default CountyCodesSlice.reducer;