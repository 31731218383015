import React from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";

interface EPCInputProps extends Omit<TextFieldProps, "variant" | "size"> {
  width?: string | number;
  height?: string | number;
  padding?: string | number;
  required?: boolean;
  onClick?: () => void;
  isSRC?: boolean;
}

const EPCInput: React.ForwardRefRenderFunction<
  HTMLInputElement,
  EPCInputProps
> = ({ width, height, padding, required, onClick, isSRC, ...props }, ref) => {
  const inputStyles: React.CSSProperties = {
    width: width || "100%",
    height: height || "auto",
    padding: padding || 0,
  };

  return (
    <TextField
      autoComplete="off"
      style={inputStyles}
      {...props}
      required={required}
      onClick={onClick}
      inputRef={ref}
      sx={{
        "& .MuiOutlinedInput-input": {
          padding: 0,
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: (theme) => theme.palette.customColors.inputBorder,
            borderRadius: 1,
            borderWidth: 0.25,
            height: inputStyles.height || "42px",
            fontSize: 22,
          },

          "&.Mui-focused fieldset": {
            borderColor: (theme) => theme.palette.customColors.focused,
          },
        },
      }}
    />
  );
};

export default React.forwardRef(EPCInput);
