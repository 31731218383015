import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Theme } from "@mui/material";
import { Popper } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "interfaces/rootState";

interface Location {
  Code: string;
  Description: string;
  IsDefault?: string;
}

interface AutocompleteComponentProps {
  widthVariant?: "small" | "medium" | "large" | "full" | "auto";
  options?: Location[];
  label?: string;
  hidden?: boolean;
  disabled?: boolean;
  onDelete?: () => void;
  isGrid?: boolean;
  value?: Location | null;
}

const defaultLocations: Location[] = [];

const AutocompleteComponent: React.FC<AutocompleteComponentProps> = ({
  widthVariant = "auto",
  options = defaultLocations,
  label,
  hidden = false,
  disabled = false,
  onDelete,
  isGrid = false,
  value,
}) => {
  const {
    loanPurposeType = "",
    city,
    state,
    county,
  } = useSelector((state: RootState) => state?.loan?.data?.loan?.property) ||
  "";

  // Function to find the default value
  const findDefaultOption = () => {
    return options.find((option) => option.IsDefault === "true") ?? null;
  };

  // Initialize state with either the passed value or the default option
  const [selectedValue, setSelectedValue] = useState<Location | null>(
    value ?? findDefaultOption()
  );

  // Update selectedValue if options or value change
  useEffect(() => {
    setSelectedValue(value ?? findDefaultOption());
  }, [options, value]);

  const handleSelectionChange = (
    event: React.ChangeEvent<{}>,
    value: Location | null
  ) => {
    setSelectedValue(value);
  };

  if (hidden) {
    return null;
  }

  const commonTextFieldStyles = {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: (theme: Theme) => theme.palette.customColors.inputBorder,
        borderRadius: "4px",
        fontSize: 22,
      },
      "& .MuiOutlinedInput-notchedOutline legend": {
        fontSize: 12,
      },
      "&.Mui-focused fieldset": {
        borderColor: (theme: Theme) => theme.palette.customColors.focused,
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: (theme: Theme) => theme.palette.customColors.focused,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: (theme: Theme) => theme.palette.customColors.focused,
      },
      "& .MuiAutocomplete-popupIndicator": {
        color: (theme: Theme) => theme.palette.customColors.focused,
      },
      "& .MuiAutocomplete-popupIndicatorOpen": {
        color: (theme: Theme) => theme.palette.customColors.focused,
      },
    },
  };

  const largeTextFieldStyles = {
    ...commonTextFieldStyles,
    width: 400,
  };

  return (
    <Autocomplete
      value={selectedValue}
      onChange={handleSelectionChange}
      options={options}
      getOptionLabel={(option) => option.Description}
      disabled={disabled}
      size={isGrid ? "small" : "medium"}
      clearIcon={null} // Remove the close icon
      PopperComponent={(props) => (
        <Popper
          {...props}
          placement="bottom-start"
          modifiers={[
            {
              name: "flip",
              enabled: false,
            },
          ]}
        />
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          InputLabelProps={{
            shrink: false,
            sx: {
              color: (theme: Theme) => theme.palette.customColors.focused,
            },
          }}
          sx={
            widthVariant === "large"
              ? largeTextFieldStyles
              : commonTextFieldStyles
          }
          disabled={disabled}
        />
      )}
      sx={{ marginY: 1 }}
    />
  );
};

export default AutocompleteComponent;
