// ** MUI Imports
import { Theme } from '@mui/material/styles';
import muiInput from 'themes/overrides/input';
import muiPaper from 'themes/overrides/paper';
import muiTable from 'themes/overrides/table';
import muiButton from 'themes/overrides/button';
import muiDivider from 'themes/overrides/divider';
import muiSnackbar from 'themes/overrides/snackbar';
import muiTypography from 'themes/overrides/typography';
import textField from 'themes/overrides/textField';
import card from 'themes/overrides/card';
import Tooltip from 'themes/overrides/toolTip';


const Overrides = (theme: Theme) => {
    const input = muiInput(theme);
    const tables = muiTable(theme);
    const button = muiButton(theme);
    const divider = muiDivider(theme);
    const snackbar = muiSnackbar(theme);
    const TextField = textField(theme);
    const MuiPaper = muiPaper();
    const Typography = muiTypography(theme);
    const Card = card(theme);
    const tooltip = Tooltip();

    return Object.assign(

        input,
        button,
        tables,
        divider,
        snackbar,
        Typography,
        TextField,
        MuiPaper,
        Card,
        tooltip

    );
};

export default Overrides;
