
const Tooltip = () => {
    return {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontWeight: 500,
                    fontSize: 10,
                    lineHeight: 1.71,
                    letterSpacing: '1px',
                    textTransform: "none"
                }
            }
        }
    };
};

export default Tooltip;
