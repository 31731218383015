import { createSlice } from "@reduxjs/toolkit";
import { fetchTransactionTypes } from "componnets/StewartSRC/utils";

 const TransactionTypes = createSlice({
  name: 'transactiontypes',
  initialState: {
    data: {},
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null
  },
  reducers: { },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactionTypes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTransactionTypes.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchTransactionTypes.rejected, (state) => {
        state.status = 'failed';
      
      });
  }
});

export default TransactionTypes.reducer;