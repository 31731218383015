

import type { FC } from 'react';
import { FormComponent } from '../TitleInsurancepremium/PremiumDetails';
import { RenderFormComponent } from 'componnets/services/RenderForms';

interface RenderTransactionDetailsProps {
    transactionDetails: FormComponent[];
}

const RenderTransactionDetails: FC<RenderTransactionDetailsProps> = ({transactionDetails}) => {

    return (

        <>
         {transactionDetails.map((detail, index) =>
                 <RenderFormComponent component={detail} index={index}/>
               )}
       </>
    );
}

export default RenderTransactionDetails;
