import React, { forwardRef,useState } from "react";
import { TextField } from "@mui/material";
import { useFormContext, useFormState } from "react-hook-form";
import { formatCurrency } from "utils/srcutils";

interface CurrencyTextFieldProps {
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: any
  name: string;
  hidden: boolean;
  disabled: boolean;
}

const CurrencyTextField = forwardRef<HTMLInputElement, CurrencyTextFieldProps>(
  ({ label, value, onBlur, name, hidden, disabled,...props }, ref) =>{ 
const [data,setData]=useState<string>(value??'')
const {setValue}=useFormContext()

    const handleChange=((event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
      console.log(event.target.value)
      const newValue = event.target.value.replace(/[^0-9.-]/g, '');
      setData(newValue)
    })
    const handleBlur=((event:React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
      const formatted =formatCurrency(data,true);
      setData(formatted)
      setValue(name,formatted)
      onBlur(formatted)
    })
    return  (
    <TextField
      label={label}
      value={data}
      disabled={disabled}
      hidden={hidden}
      onChange={handleChange}
      onBlur={handleBlur}
      inputRef={ref} // Forward the ref to the TextField
      fullWidth
      sx={{
        "& .MuiInputBase-input": {
          fontSize: 14,
          padding: 1,
          height: 34,
        },
      }}
    />
  )}
);

export default CurrencyTextField;
