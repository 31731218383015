import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { getTransactionTypeParam } from "utils/helpers";
import { setSelctedTransaction } from "redux/features/srcSlice";
import { useEffect } from "react";
import {
  getTransactionData,
  modifyConfigBasedOnCodes,
  setTransactionDetailsFieldVisibility,
} from "./utils";
import { updateFieldVisibility } from "redux/features/transactionDetailsSlice";
import { setData } from "redux/features/titleInsurancePremiumSlice";
import {
  FormSchema,
  WithoutSplitSchema,
  formWithSplit,
  withoutSplit,
} from "../TitleInsurancepremium/utils";
import { useDeepCompareEffect } from "hooks/useDeepCompareMemoize";
import RenderTransactionDetails from "./RenderFields";

function TransactionDetailsUI() {
  const dispatch = useDispatch();
  const {
    loanPurposeType,
    PolicyInsuredType,
    transactionDetails,
    transacTypes,
    selectedTransObject,
  } = useSelector(getTransactionData);

  useEffect(() => {
    const transacTypeParam = getTransactionTypeParam(loanPurposeType) || "";
    const selectedTransac = transacTypes.filter(
      (type: any) => type.Code === transacTypeParam?.toUpperCase()
    ) || [{}];
    dispatch(
      setSelctedTransaction({
        transacTypeParam: transacTypeParam,
        selectedTransac: selectedTransac[0],
      })
    );
  }, [loanPurposeType, transacTypes]);

  useEffect(() => {
    const transactionDetailsData = setTransactionDetailsFieldVisibility(
      transactionDetails,
      PolicyInsuredType
    );
    transactionDetailsData.forEach((cv) => {
      dispatch(
        updateFieldVisibility({ fieldName: cv.name, hidden: cv.hidden })
      );
    });
  }, [PolicyInsuredType]);

  useDeepCompareEffect(() => {
    if (!Object.keys(selectedTransObject).length) return;

    const isSplit: boolean = Boolean(
      selectedTransObject?.IsDisplaySplits === "true"
    );
    const JSonData: FormSchema | WithoutSplitSchema = isSplit
      ? formWithSplit
      : withoutSplit;

    const config = modifyConfigBasedOnCodes(JSonData, PolicyInsuredType);
    dispatch(setData(config));
  }, [selectedTransObject, PolicyInsuredType]);
  return (
    <Box
      alignItems="center"
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "100%",
        gap: "1rem",
        paddingBottom: 4,
      }}
    >
      <RenderTransactionDetails
        transactionDetails={Object.values(transactionDetails)}
      />
    </Box>
  );
}

export default TransactionDetailsUI;
