import { RootState } from "interfaces/rootState";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AddOperationIdInStore } from "redux/features/operationId";
import { formatCurrentDate } from "utils/helpers";
import EPCModal from "../EpcModal";
import MultiLineTypography from "../multiLineText";

const ExceptionBox = () => {
  const StewartContextID =
    useSelector((st: RootState) => st.loan.options?.StewartContextID) ||
    "Not Available";
  const operationId =
    useSelector((st: RootState) => st.operationId?.operationId) || "";

  const [showCustomerCareInfo, setShowCustomerCareInfo] = useState(true);
  const dispatch = useDispatch();

  return (
    <EPCModal
      open={showCustomerCareInfo && operationId?.length}
      onClose={() => {
        setShowCustomerCareInfo(false);
        dispatch(AddOperationIdInStore(""));
      }}
      title="Stewart Title Company"
      showOkButton
    >
      <MultiLineTypography
        text={`There was an error processing your request. Please contact Stewart Customer Care at (855)330-2800 for assistance.\n
                  Please refer to code: Encompass\n
                  Context ID: ${StewartContextID} \n
									Operation ID: ${operationId || "Not Available"} \n
                  Error Timestamp: ${formatCurrentDate() || ""}`}
      />
    </EPCModal>
  );
};

export default ExceptionBox;
