import { forwardRef } from "react";
import TextField from "@mui/material/TextField";

export interface MuiTextFieldProps {
  label: string;
  error?: boolean;
  helperText?: string;
  hidden?: boolean;
  value?: string | number;
  onBlur: any;
}

const MuiTextField = forwardRef<HTMLInputElement, MuiTextFieldProps>(
  (
    { label, error, helperText, hidden = false, value = "", onBlur, ...props },
    ref
  ) => {
    // console.log("props:", props);
    return (
      <TextField
        {...props}
        value={value}
        inputRef={ref}
        onBlur={onBlur}
        label={label}
        error={error}
        helperText={helperText}
        variant="outlined"
        fullWidth
      />
    );
  }
);

export default MuiTextField;
