import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface statusListState {
  data: string[];
}

const initialState: statusListState = {
    data: []
};

const statusList = createSlice({
    name: "statusList",
    initialState,
    reducers: {
        reset: () => initialState,
        setStatusList: (state, action: PayloadAction<string[]>) => {
            return { ...state, data: action?.payload ?? [] };
        }
    }
});

export const { setStatusList } = statusList.actions;
export default statusList.reducer;
