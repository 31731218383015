import { ReactNode } from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

interface AddButtonProps extends ButtonProps {
  children?: ReactNode; // Make children optional to allow usage without them
  variant?: "text" | "outlined" | "contained" | undefined;
  label: string;
  sx?: any; 
}

const AddButton = ({
  children,
  variant = "outlined",
  sx,
  ...props
}: AddButtonProps) => {
  return (
    <Button
      variant={variant}
      startIcon={<AddIcon />}
      sx={{
        bgcolor: "transparent", 
        color: (theme)=>theme.palette.customColors.buttonColor,
        borderRadius:'4px',border:2,
        borderColor:(theme)=>theme.palette.customColors.buttonColor,
        marginY:6,           
        ...sx,                  
      }}
      {...props}
    >
      {props.label} 
    </Button>
  );
};

export default AddButton;
