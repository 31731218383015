import type { FC } from 'react';
import CustomAccordion from 'componnets/molecules/Accordion';
import PremimumTitleInsurance from './PremiumDetails';

interface TitleInsuranceProps {}

const TitleInsurance: FC<TitleInsuranceProps> = () => {
    return (

        <CustomAccordion
        summary='Title Insurance Premium'
        reissueText="Reissue rates may be available for this estimate"
        >
        <PremimumTitleInsurance/>
        </CustomAccordion>
    );
}

export default TitleInsurance;
