// ** MUI Imports
import { Theme } from '@mui/material/styles';

const input = (theme: Theme) => {
    return {
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: theme.palette.text.secondary
                }
            }
        },


        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
                        borderColor: `${theme.palette.common.black}`
                    },
                    '&:hover.Mui-error .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.common.black
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: `${theme.palette.common.black}`
                    },
                    '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.common.black
                    },
                    borderRadius: 0

                }
            }
        }
    };
};

export default input;
