import { FC } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  Grid as Muigrid,
 } from "@mui/material";
import { RenderFormComponent } from "componnets/services/RenderForms";

interface GridProps {
  headers: any;
  rows: any;
  headersAlign:string;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  paddingBlock: theme.spacing(1),
  verticalAlign: "middle",
  

}));

const DividerCell = styled(TableCell)(({ theme }) => ({
  paddingBlock: theme.spacing(1),
  textAlign: "center",
  verticalAlign: "middle",
}));

const Grid: FC<GridProps> = ({ headers, rows,headersAlign='right' }) => {

  const totalGridSize = headers.reduce(
    (acc: number, header: Record<string, any>) => acc + header.gridSize,
    0
  );
console.log(rows,'rows')
  return (
    // <Muigrid container justifyContent="center" sx={{ padding: 0, margin: 0 }}>
      <Muigrid item xs={12}>
        <TableContainer component={Paper} sx={{ width: '100%' }}>
          <Table sx={{ width: '100%',marginBottom:4 }}>
            <TableHead>
              <TableRow>
                {headers?.map((header: Record<string, any>) => {
                  const width = `${(header.gridSize / totalGridSize) * 100}%`;
                  if (header.fieldType === "Divider") {
                    return (
                      <DividerCell
                        key={`header-divider-${header.label}`}
                        colSpan={header.gridSize}
                      >
                        &nbsp;
                      </DividerCell>
                    );
                  }
                  return (
                    <StyledTableCell
                      key={header.label}
                      colSpan={header.gridSize}
                      sx={{
                        width,
                        paddingBottom: 4,
                        fontSize: "17px",
                        fontWeight: 600,
                        textAlign:headersAlign
                      }}
                    >
                      {header.label}
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row: Record<string, any>, rowIndex: any) => (
                <TableRow key={rowIndex}>
                  {row.map((cell: any, cellIndex: any) => {
                    const width = `${(cell.gridSize / totalGridSize) * 100}%`;
                    if (cell.fieldType === "Divider") {
                      return (
                        <DividerCell
                          key={`divider-${cellIndex}`}
                          colSpan={cell.gridSize}
                          sx={{ width, fontSize: 20 }}
                        >
                          /
                        </DividerCell>
                      );
                    }
                    return (
                      <StyledTableCell
                        key={cell.label || cellIndex}
                        colSpan={cell.gridSize}
                        sx={{ width }}
                      >
                        <RenderFormComponent component={cell} index={cellIndex} />
                      </StyledTableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Muigrid>
    // </Muigrid>
  );
};

export default Grid;
