export interface County {
    Name: string;
    CountyCode: string;
}

export interface State {
    "@StateCode": string;
    County: County;
}

export interface CountyList {
    State: State[];
}

export function getCountyCode(countyList: CountyList | null | undefined, stateCode: string | null | undefined, countyName: string | null | undefined): string  {
    if (!countyList || !countyList.State || !stateCode || !countyName) {
        return '';
    }

    for (const state of countyList.State) {
        if (state["@StateCode"] === stateCode && state.County.Name === countyName) {
            return state.County.CountyCode;
        }
    }
    return '';
}